/* tslint:disable */
/* eslint-disable */
/**
 * nominaMs
 * API para nomina SNI
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Información de Adeudo
 * @export
 * @interface Adeudo
 */
export interface Adeudo {
    /**
     * Id de la solicitud en la que se registro el adeudo
     * @type {string}
     * @memberof Adeudo
     */
    'solicitudId'?: string;
    /**
     * Indica si el adeudo está activo
     * @type {boolean}
     * @memberof Adeudo
     */
    'activo'?: boolean;
    /**
     * Monto total del adeudo
     * @type {number}
     * @memberof Adeudo
     */
    'monto'?: number;
    /**
     * Saldo del adeudo en otras palabras monto restante por pagar
     * @type {number}
     * @memberof Adeudo
     */
    'saldo'?: number;
}
/**
 * Campos para consultar un archivo
 * @export
 * @interface Archivo
 */
export interface Archivo {
    /**
     * Id del archivo
     * @type {string}
     * @memberof Archivo
     */
    'id'?: string;
    /**
     * Nombre del archivo
     * @type {string}
     * @memberof Archivo
     */
    'nombre'?: string;
    /**
     * Total de registros que contiene el archivo
     * @type {number}
     * @memberof Archivo
     */
    'total_registros'?: number;
    /**
     * Clave de la secretaría técnica (Campo DMS)
     * @type {string}
     * @memberof Archivo
     */
    'secretaria_tecnica_clave'?: string;
    /**
     * Clave de la convocatoria (Campo DMS)
     * @type {string}
     * @memberof Archivo
     */
    'convocatoria_clave'?: string;
    /**
     * Clave de la solicitud (Campo DMS)
     * @type {string}
     * @memberof Archivo
     */
    'solicitud_clave'?: string;
    /**
     * 
     * @type {Etapa}
     * @memberof Archivo
     */
    'etapa'?: Etapa;
    /**
     * Ruta relativa en la que se guardará el archivo (Campo DMS)
     * @type {string}
     * @memberof Archivo
     */
    'rutaRelativa'?: string;
    /**
     * URL asignada por el DMS para acceder al archivo
     * @type {string}
     * @memberof Archivo
     */
    'url_dms'?: string;
    /**
     * Referencia a la nómina a la que pertenece el archivo
     * @type {string}
     * @memberof Archivo
     */
    'id_nomina'?: string;
    /**
     * JobId de la tarea ejecutada en segundo plano
     * @type {string}
     * @memberof Archivo
     */
    'job_id'?: string;
    /**
     * 
     * @type {TipoArchivo}
     * @memberof Archivo
     */
    'tipo_archivo'?: TipoArchivo;
    /**
     * Indicador del procesado del archivo en la prenómina
     * @type {boolean}
     * @memberof Archivo
     */
    'procesado'?: boolean;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof Archivo
     */
    'activo'?: boolean;
    /**
     * Errores al validar el archivo
     * @type {Array<string>}
     * @memberof Archivo
     */
    'errores'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AsignacionPersona
 */
export interface AsignacionPersona {
    /**
     * ID de la asignación de persona
     * @type {string}
     * @memberof AsignacionPersona
     */
    'id'?: string;
    /**
     * 
     * @type {MovimientoPago}
     * @memberof AsignacionPersona
     */
    'movimientoPago'?: MovimientoPago;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof AsignacionPersona
     */
    'persona'?: ResumenPersona;
    /**
     * Curriculum vitae único de la persona
     * @type {number}
     * @memberof AsignacionPersona
     */
    'cvu'?: number;
    /**
     * Clave de registro único de población
     * @type {string}
     * @memberof AsignacionPersona
     */
    'curp'?: string;
    /**
     * Registro Federal de Contribuyentes
     * @type {string}
     * @memberof AsignacionPersona
     */
    'rfc'?: string;
    /**
     * Nombre de la persona
     * @type {string}
     * @memberof AsignacionPersona
     */
    'nombre'?: string;
    /**
     * Apellido paterno de la persona
     * @type {string}
     * @memberof AsignacionPersona
     */
    'apellido_paterno'?: string;
    /**
     * Apellido materno de la persona
     * @type {string}
     * @memberof AsignacionPersona
     */
    'apellido_materno'?: string;
    /**
     * 
     * @type {TipoReceptor}
     * @memberof AsignacionPersona
     */
    'tipo_receptor'?: TipoReceptor;
    /**
     * Clave bancaria estandarizada
     * @type {string}
     * @memberof AsignacionPersona
     */
    'clabe'?: string;
    /**
     * Monto asignado a la persona
     * @type {number}
     * @memberof AsignacionPersona
     */
    'monto'?: number;
    /**
     * Porcentaje asignado a la persona
     * @type {number}
     * @memberof AsignacionPersona
     */
    'porcentaje'?: number;
    /**
     * Monto a pagar a la persona asignada
     * @type {number}
     * @memberof AsignacionPersona
     */
    'monto_pagar'?: number;
}
/**
 * Información que sustenta el pago a un ayudante
 * @export
 * @interface Ayudante
 */
export interface Ayudante {
    /**
     * Cvu de la persona
     * @type {string}
     * @memberof Ayudante
     */
    'cvu'?: string;
    /**
     * Curp de la persona
     * @type {string}
     * @memberof Ayudante
     */
    'curp'?: string;
    /**
     * Nombre de la persona
     * @type {string}
     * @memberof Ayudante
     */
    'nombre'?: string;
    /**
     * Apellido paterno de la persona
     * @type {string}
     * @memberof Ayudante
     */
    'apellido_paterno'?: string;
    /**
     * Apellido materno de la persona
     * @type {string}
     * @memberof Ayudante
     */
    'apellido_materno'?: string;
    /**
     * Cantidad de umas asignadas al ayudante
     * @type {number}
     * @memberof Ayudante
     */
    'cantidadUma'?: number;
}
/**
 * Información que sustenta el pago a un beneficiario
 * @export
 * @interface Beneficiario
 */
export interface Beneficiario {
    /**
     * Total de meses a pagar
     * @type {number}
     * @memberof Beneficiario
     */
    'totalMeses'?: number;
    /**
     * Fecha de la UMA a considerar en el cálculo
     * @type {string}
     * @memberof Beneficiario
     */
    'fechaFallecimientoInvestigador'?: string;
    /**
     * Fecha en que se notifica el fallecimiento del investigador
     * @type {string}
     * @memberof Beneficiario
     */
    'fechaNotificacionFallecimiento'?: string;
    /**
     * Porcentaje asignado al beneficiario
     * @type {number}
     * @memberof Beneficiario
     */
    'porcentaje'?: number;
    /**
     * 
     * @type {NivelSni}
     * @memberof Beneficiario
     */
    'nivelSniInvestigador'?: NivelSni;
    /**
     * Fecha de inicio de la acreditación
     * @type {string}
     * @memberof Beneficiario
     */
    'inicioAcreditacion'?: string;
    /**
     * Fecha de fin de la acreditación
     * @type {string}
     * @memberof Beneficiario
     */
    'finAcreditacion'?: string;
    /**
     * Cvu de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'cvu'?: string;
    /**
     * Curp de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'curp'?: string;
    /**
     * rfc de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'rfc'?: string;
    /**
     * Nombre de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'nombre'?: string;
    /**
     * Apellido paterno de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'apellido_paterno'?: string;
    /**
     * Apellido materno de la persona
     * @type {string}
     * @memberof Beneficiario
     */
    'apellido_materno'?: string;
}
/**
 * Información del calculo y el valor del periodo de las Umas
 * @export
 * @interface CalculoPeriodoUma
 */
export interface CalculoPeriodoUma {
    /**
     * 
     * @type {number}
     * @memberof CalculoPeriodoUma
     */
    'montoTotal'?: number;
    /**
     * 
     * @type {Array<PeriodoUma>}
     * @memberof CalculoPeriodoUma
     */
    'periodosUmas'?: Array<PeriodoUma>;
}
/**
 * Catálogo de categoría de movimientos de pago
 * @export
 * @interface CategoriaMovimientoPago
 */
export interface CategoriaMovimientoPago {
    /**
     * Id de categoría de movimiento de pago
     * @type {string}
     * @memberof CategoriaMovimientoPago
     */
    'id'?: string;
    /**
     * Clave del tipo de movimiento
     * @type {string}
     * @memberof CategoriaMovimientoPago
     */
    'clave'?: string;
    /**
     * Descripción del tipo de movimiento
     * @type {string}
     * @memberof CategoriaMovimientoPago
     */
    'descripcion'?: string;
    /**
     * Indica si se requiere adjuntar un documento
     * @type {boolean}
     * @memberof CategoriaMovimientoPago
     */
    'requiereDocumento'?: boolean;
    /**
     * 
     * @type {TipoMovimientoPago}
     * @memberof CategoriaMovimientoPago
     */
    'tipoMovimientoPago'?: TipoMovimientoPago;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof CategoriaMovimientoPago
     */
    'activo'?: boolean;
}
/**
 * Catálogo de detalles de movimientos de pago
 * @export
 * @interface DetalleMovimientoPago
 */
export interface DetalleMovimientoPago {
    /**
     * Id de detalle de movimiento de pago
     * @type {string}
     * @memberof DetalleMovimientoPago
     */
    'id'?: string;
    /**
     * Clave del detalle del movimiento
     * @type {string}
     * @memberof DetalleMovimientoPago
     */
    'clave'?: string;
    /**
     * Descripción del detalle del movimiento
     * @type {string}
     * @memberof DetalleMovimientoPago
     */
    'descripcion'?: string;
    /**
     * 
     * @type {CategoriaMovimientoPago}
     * @memberof DetalleMovimientoPago
     */
    'categoriaMovimientoPago'?: CategoriaMovimientoPago;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof DetalleMovimientoPago
     */
    'activo'?: boolean;
}
/**
 * Información del encabezado de los padrones generados en expediente persona
 * @export
 * @interface EncabezadoPadronNomina
 */
export interface EncabezadoPadronNomina {
    /**
     * Id del encabezado de padrón generado en expediente persona
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'idEncabezadoPadron'?: string;
    /**
     * 
     * @type {Nomina}
     * @memberof EncabezadoPadronNomina
     */
    'nomina'?: Nomina;
    /**
     * Fecha de generación de la carga
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'fechaGeneracion'?: string;
    /**
     * Cvu del responsable de la generación de carga
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'cvu'?: string;
    /**
     * rfc
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'rfc'?: string;
    /**
     * Clave bancaria estandarizada
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'clabe'?: string;
    /**
     * Nombre del responsable de la generación de carga
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'nombre'?: string;
    /**
     * Apellido paterno del responsable de la generación de carga
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'apellidoPaterno'?: string;
    /**
     * Apellido materno del responsable de la generación de carga
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'apellidoMaterno'?: string;
    /**
     * Cantidad de ayudantes en la generación de la carga
     * @type {number}
     * @memberof EncabezadoPadronNomina
     */
    'cantidadAyudantes'?: number;
    /**
     * Cantidad de investigadores en la generación de la carga
     * @type {number}
     * @memberof EncabezadoPadronNomina
     */
    'cantidadInvestigadores'?: number;
    /**
     * Cantidad total de receptores en la generación de la carga
     * @type {number}
     * @memberof EncabezadoPadronNomina
     */
    'total'?: number;
    /**
     * Cantidad total de receptores en la generación de la carga
     * @type {number}
     * @memberof EncabezadoPadronNomina
     */
    'cantidadPagosProcesados'?: number;
    /**
     * Este es el porcentaje que se tomará de su monto pago para deducción del adeudo que tenga.
     * @type {number}
     * @memberof EncabezadoPadronNomina
     */
    'porcentajeAdeudo'?: number;
    /**
     * Id del encabezado de padrón
     * @type {string}
     * @memberof EncabezadoPadronNomina
     */
    'id'?: string;
    /**
     * Indicador de prenómina generada
     * @type {boolean}
     * @memberof EncabezadoPadronNomina
     */
    'prenominaGenerada'?: boolean;
    /**
     * Indicador para saber si se está generando la prenómina
     * @type {boolean}
     * @memberof EncabezadoPadronNomina
     */
    'generandoPagos'?: boolean;
    /**
     * 
     * @type {Array<EventoEncabezadoPadronNomina>}
     * @memberof EncabezadoPadronNomina
     */
    'eventos_nomina'?: Array<EventoEncabezadoPadronNomina>;
    /**
     * 
     * @type {EventoEncabezadoPadronNomina}
     * @memberof EncabezadoPadronNomina
     */
    'eventoActual'?: EventoEncabezadoPadronNomina;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EncabezadoPadronNomina
     */
    'activo'?: boolean;
}
/**
 * 
 * @export
 * @interface EncabezadoPadronNominaAllOf
 */
export interface EncabezadoPadronNominaAllOf {
    /**
     * Id del encabezado de padrón
     * @type {string}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'id'?: string;
    /**
     * Indicador de prenómina generada
     * @type {boolean}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'prenominaGenerada'?: boolean;
    /**
     * Indicador para saber si se está generando la prenómina
     * @type {boolean}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'generandoPagos'?: boolean;
    /**
     * 
     * @type {Array<EventoEncabezadoPadronNomina>}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'eventos_nomina'?: Array<EventoEncabezadoPadronNomina>;
    /**
     * 
     * @type {EventoEncabezadoPadronNomina}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'eventoActual'?: EventoEncabezadoPadronNomina;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EncabezadoPadronNominaAllOf
     */
    'activo'?: boolean;
}
/**
 * Estatus del encabezado padrón nómina
 * @export
 * @enum {string}
 */

export const EstatusEncabezadoPadronNomina = {
    PROCESANDO: 'PROCESANDO',
    ELIMINANDO: 'ELIMINANDO',
    INCOMPLETO: 'INCOMPLETO',
    GENERADO: 'GENERADO'
} as const;

export type EstatusEncabezadoPadronNomina = typeof EstatusEncabezadoPadronNomina[keyof typeof EstatusEncabezadoPadronNomina];


/**
 * Estuatus de movimiento
 * @export
 * @enum {string}
 */

export const EstatusMovimientoPago = {
    PROGRAMADO: 'PROGRAMADO',
    ACTIVADO: 'ACTIVADO',
    DESACTIVADO: 'DESACTIVADO',
    CONCLUIDO: 'CONCLUIDO'
} as const;

export type EstatusMovimientoPago = typeof EstatusMovimientoPago[keyof typeof EstatusMovimientoPago];


/**
 * Estatus de la nómina
 * @export
 * @enum {string}
 */

export const EstatusNomina = {
    CONFIGURADA: 'CONFIGURADA',
    CALCULADA: 'CALCULADA',
    APLICADA: 'APLICADA',
    CONCILIADA: 'CONCILIADA',
    CERRADA: 'CERRADA'
} as const;

export type EstatusNomina = typeof EstatusNomina[keyof typeof EstatusNomina];


/**
 * Estatus del pago
 * @export
 * @enum {string}
 */

export const EstatusPago = {
    CALCULADO: 'CALCULADO',
    POR_PAGAR: 'POR_PAGAR',
    PAGADO: 'PAGADO',
    RECHAZADO: 'RECHAZADO',
    CANCELADO: 'CANCELADO',
    EXCLUIDO: 'EXCLUIDO'
} as const;

export type EstatusPago = typeof EstatusPago[keyof typeof EstatusPago];


/**
 * 
 * @export
 * @enum {string}
 */

export const Etapa = {
    PREREGISTRO: 'PREREGISTRO',
    REGISTRO: 'REGISTRO',
    EVALUACION: 'EVALUACION',
    FORMALIZACION: 'FORMALIZACION',
    SEGUIMIENTO: 'SEGUIMIENTO'
} as const;

export type Etapa = typeof Etapa[keyof typeof Etapa];


/**
 * Histórico de estatus
 * @export
 * @interface EventoEncabezadoPadronNomina
 */
export interface EventoEncabezadoPadronNomina {
    /**
     * 
     * @type {EstatusEncabezadoPadronNomina}
     * @memberof EventoEncabezadoPadronNomina
     */
    'estatus'?: EstatusEncabezadoPadronNomina;
    /**
     * Cvu de la persona que realizó la inserción
     * @type {number}
     * @memberof EventoEncabezadoPadronNomina
     */
    'cvu'?: number;
    /**
     * Fecha de la inserción
     * @type {string}
     * @memberof EventoEncabezadoPadronNomina
     */
    'fecha'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EventoEncabezadoPadronNomina
     */
    'activo'?: boolean;
}
/**
 * Estatus del movimiento de pago e información de auditoria
 * @export
 * @interface EventoMovimientoPago
 */
export interface EventoMovimientoPago {
    /**
     * 
     * @type {EstatusMovimientoPago}
     * @memberof EventoMovimientoPago
     */
    'estatus'?: EstatusMovimientoPago;
    /**
     * Observación generada en el cambio de estatus
     * @type {string}
     * @memberof EventoMovimientoPago
     */
    'observacion'?: string;
    /**
     * Cvu de la persona que realizó la inserción
     * @type {number}
     * @memberof EventoMovimientoPago
     */
    'cvu'?: number;
    /**
     * Fecha de la inserción
     * @type {string}
     * @memberof EventoMovimientoPago
     */
    'fecha'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EventoMovimientoPago
     */
    'activo'?: boolean;
}
/**
 * Histórico de estatus
 * @export
 * @interface EventoNomina
 */
export interface EventoNomina {
    /**
     * 
     * @type {EstatusNomina}
     * @memberof EventoNomina
     */
    'estatus'?: EstatusNomina;
    /**
     * Cvu de la persona que realizó la inserción
     * @type {number}
     * @memberof EventoNomina
     */
    'cvu'?: number;
    /**
     * Fecha de la inserción
     * @type {string}
     * @memberof EventoNomina
     */
    'fecha'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EventoNomina
     */
    'activo'?: boolean;
}
/**
 * Histórico de estatus de pago
 * @export
 * @interface EventoPago
 */
export interface EventoPago {
    /**
     * 
     * @type {EstatusPago}
     * @memberof EventoPago
     */
    'estatus'?: EstatusPago;
    /**
     * Observación generada en el cambio de estatus
     * @type {string}
     * @memberof EventoPago
     */
    'observacion'?: string;
    /**
     * Cvu de la persona que realizó la inserción
     * @type {number}
     * @memberof EventoPago
     */
    'cvu'?: number;
    /**
     * Fecha de la inserción
     * @type {string}
     * @memberof EventoPago
     */
    'fecha'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof EventoPago
     */
    'activo'?: boolean;
}
/**
 * Frecuencia del movimiento pago
 * @export
 * @enum {string}
 */

export const FrecuenciaMovimientoPago = {
    ADICIONAL: 'ADICIONAL',
    PROGRAMADO: 'PROGRAMADO',
    AMBAS: 'AMBAS'
} as const;

export type FrecuenciaMovimientoPago = typeof FrecuenciaMovimientoPago[keyof typeof FrecuenciaMovimientoPago];


/**
 * Información de pagos del movimiento de pago
 * @export
 * @interface HistorialPagoMovimientoPago
 */
export interface HistorialPagoMovimientoPago {
    /**
     * Id del pago de movimiento de pago
     * @type {string}
     * @memberof HistorialPagoMovimientoPago
     */
    'id'?: string;
    /**
     * Clave de la nómina
     * @type {string}
     * @memberof HistorialPagoMovimientoPago
     */
    'claveNomina'?: string;
    /**
     * Fecha de aplicación del pago
     * @type {string}
     * @memberof HistorialPagoMovimientoPago
     */
    'fechaAplicacion'?: string;
    /**
     * Monto a aplicar como pago
     * @type {number}
     * @memberof HistorialPagoMovimientoPago
     */
    'montoAplicar'?: number;
    /**
     * Monto aplicado del pago
     * @type {number}
     * @memberof HistorialPagoMovimientoPago
     */
    'montoAplicado'?: number;
    /**
     * Monto no aplicado del pago
     * @type {number}
     * @memberof HistorialPagoMovimientoPago
     */
    'montoNoAplicado'?: number;
    /**
     * 
     * @type {TipoReceptor}
     * @memberof HistorialPagoMovimientoPago
     */
    'tipoReceptor'?: TipoReceptor;
}
/**
 * Información del Archivo
 * @export
 * @interface InformacionArchivo
 */
export interface InformacionArchivo {
    /**
     * Nombre del archivo
     * @type {string}
     * @memberof InformacionArchivo
     */
    'nombre'?: string;
    /**
     * El contenido del documento codificado en base 64
     * @type {string}
     * @memberof InformacionArchivo
     */
    'contenido'?: string;
    /**
     * El tipo MIME del contenido
     * @type {string}
     * @memberof InformacionArchivo
     */
    'mediaType'?: string;
    /**
     * Tamaño del archivo
     * @type {number}
     * @memberof InformacionArchivo
     */
    'size'?: number;
    /**
     * URI del archivo
     * @type {string}
     * @memberof InformacionArchivo
     */
    'uri'?: string;
}
/**
 * Campos para listar una nómina
 * @export
 * @interface InformacionNomina
 */
export interface InformacionNomina {
    /**
     * Mes de cálculo de la nómina
     * @type {number}
     * @memberof InformacionNomina
     */
    'mes': number;
    /**
     * Año de cálculo de la nómina
     * @type {number}
     * @memberof InformacionNomina
     */
    'anio': number;
    /**
     * Descripción de la nómina
     * @type {string}
     * @memberof InformacionNomina
     */
    'descripcion': string;
    /**
     * 
     * @type {TipoNomina}
     * @memberof InformacionNomina
     */
    'tipo_nomina': TipoNomina;
    /**
     * 
     * @type {Array<EventoNomina>}
     * @memberof InformacionNomina
     */
    'eventos_nomina'?: Array<EventoNomina>;
    /**
     * Indica si los pagos rechazados ya fueron incluidos en la nómina
     * @type {boolean}
     * @memberof InformacionNomina
     */
    'rechazos_incluidos'?: boolean;
    /**
     * Estatus lógico del registro de la nómina
     * @type {boolean}
     * @memberof InformacionNomina
     */
    'activo'?: boolean;
    /**
     * ID de un NominaSample
     * @type {string}
     * @memberof InformacionNomina
     */
    'id'?: string;
    /**
     * Clave de la nómina
     * @type {string}
     * @memberof InformacionNomina
     */
    'clave'?: string;
    /**
     * Es el nombre del mes de la nómina
     * @type {string}
     * @memberof InformacionNomina
     */
    'mesNombre'?: string;
    /**
     * 
     * @type {EventoNomina}
     * @memberof InformacionNomina
     */
    'eventoActual'?: EventoNomina;
    /**
     * Indicador para saber si se están aplicando los movimientos programados
     * @type {boolean}
     * @memberof InformacionNomina
     */
    'aplicandoMovimientosProgramados'?: boolean;
    /**
     * Indicador para saber si se están enviando los pagos a historico
     * @type {boolean}
     * @memberof InformacionNomina
     */
    'enviando_pagos_historico'?: boolean;
}
/**
 * 
 * @export
 * @interface InformacionNominaAllOf
 */
export interface InformacionNominaAllOf {
    /**
     * ID de un NominaSample
     * @type {string}
     * @memberof InformacionNominaAllOf
     */
    'id'?: string;
    /**
     * Clave de la nómina
     * @type {string}
     * @memberof InformacionNominaAllOf
     */
    'clave'?: string;
    /**
     * Es el nombre del mes de la nómina
     * @type {string}
     * @memberof InformacionNominaAllOf
     */
    'mesNombre'?: string;
    /**
     * 
     * @type {EventoNomina}
     * @memberof InformacionNominaAllOf
     */
    'eventoActual'?: EventoNomina;
    /**
     * Indicador para saber si se están aplicando los movimientos programados
     * @type {boolean}
     * @memberof InformacionNominaAllOf
     */
    'aplicandoMovimientosProgramados'?: boolean;
    /**
     * Indicador para saber si se están enviando los pagos a historico
     * @type {boolean}
     * @memberof InformacionNominaAllOf
     */
    'enviando_pagos_historico'?: boolean;
}
/**
 * Información que sustenta el pago a un investigador
 * @export
 * @interface Investigador
 */
export interface Investigador {
    /**
     * 
     * @type {NivelSni}
     * @memberof Investigador
     */
    'nivelSni'?: NivelSni;
    /**
     * Fecha de inicio de la acreditación
     * @type {string}
     * @memberof Investigador
     */
    'inicioAcreditacion'?: string;
    /**
     * Fecha de fin de la acreditación
     * @type {string}
     * @memberof Investigador
     */
    'finAcreditacion'?: string;
    /**
     * Indica si se trata de una incapacidad permanente
     * @type {boolean}
     * @memberof Investigador
     */
    'incapacidad'?: boolean;
}
/**
 * Información de movimiento de pago
 * @export
 * @interface MovimientoPago
 */
export interface MovimientoPago {
    /**
     * Clave del movimiento
     * @type {string}
     * @memberof MovimientoPago
     */
    'clave'?: string;
    /**
     * Justificación del movimiento
     * @type {string}
     * @memberof MovimientoPago
     */
    'justificacion'?: string;
    /**
     * Monto del movimiento
     * @type {number}
     * @memberof MovimientoPago
     */
    'monto'?: number;
    /**
     * Porcentaje del pago que implica el movimiento
     * @type {number}
     * @memberof MovimientoPago
     */
    'porcentaje'?: number;
    /**
     * Fecha en que inicia el movimiento programado
     * @type {string}
     * @memberof MovimientoPago
     */
    'inicio'?: string;
    /**
     * Fecha en que finaliza el movimiento programado
     * @type {string}
     * @memberof MovimientoPago
     */
    'fin'?: string;
    /**
     * Monto total del movimiento programado
     * @type {number}
     * @memberof MovimientoPago
     */
    'montoTotal'?: number;
    /**
     * Monto aplicado del movimiento programado
     * @type {number}
     * @memberof MovimientoPago
     */
    'montoAplicado'?: number;
    /**
     * Monto remanente del movimiento programado
     * @type {number}
     * @memberof MovimientoPago
     */
    'montoRemanente'?: number;
    /**
     * Cantidad de umas del movimiento
     * @type {number}
     * @memberof MovimientoPago
     */
    'cantidadUmas'?: number;
    /**
     * Clave del detalle del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'claveDetalle'?: string;
    /**
     * Descripción del detalle del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'descripcionDetalle'?: string;
    /**
     * Clave de la categoría del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'claveCategoria'?: string;
    /**
     * Descripción de la categoría del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'descripcionCategoria'?: string;
    /**
     * Clave del tipo del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'claveTipo'?: string;
    /**
     * Descripción del tipo del movimiento de pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'descripcionTipo'?: string;
    /**
     * 
     * @type {Archivo}
     * @memberof MovimientoPago
     */
    'archivo'?: Archivo;
    /**
     * 
     * @type {Array<EventoMovimientoPago>}
     * @memberof MovimientoPago
     */
    'eventosMovimientoPago'?: Array<EventoMovimientoPago>;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof MovimientoPago
     */
    'persona'?: ResumenPersona;
    /**
     * 
     * @type {InformacionArchivo}
     * @memberof MovimientoPago
     */
    'informacionArchivo'?: InformacionArchivo;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof MovimientoPago
     */
    'activo'?: boolean;
    /**
     * Indica si se trata de un movimiento adicional
     * @type {boolean}
     * @memberof MovimientoPago
     */
    'adicional'?: boolean;
    /**
     * 
     * @type {FrecuenciaMovimientoPago}
     * @memberof MovimientoPago
     */
    'frecuenciaMovimientoPago'?: FrecuenciaMovimientoPago;
    /**
     * 
     * @type {CategoriaMovimientoPago}
     * @memberof MovimientoPago
     */
    'categoriaMovimientoPago'?: CategoriaMovimientoPago;
    /**
     * Indica si la documentación de movimiento está completa
     * @type {boolean}
     * @memberof MovimientoPago
     */
    'documentacionCompleta'?: boolean;
    /**
     * 
     * @type {DetalleMovimientoPago}
     * @memberof MovimientoPago
     */
    'detalleMovimientoPago'?: DetalleMovimientoPago;
    /**
     * 
     * @type {Array<PagoMovimientoPago>}
     * @memberof MovimientoPago
     */
    'pagoMovimientoPago'?: Array<PagoMovimientoPago>;
    /**
     * 
     * @type {Array<AsignacionPersona>}
     * @memberof MovimientoPago
     */
    'asignacionPersona'?: Array<AsignacionPersona>;
    /**
     * Id de un movimiento pago
     * @type {string}
     * @memberof MovimientoPago
     */
    'id'?: string;
    /**
     * 
     * @type {EventoMovimientoPago}
     * @memberof MovimientoPago
     */
    'eventoActual'?: EventoMovimientoPago;
}
/**
 * 
 * @export
 * @interface MovimientoPagoAllOf
 */
export interface MovimientoPagoAllOf {
    /**
     * Id de un movimiento pago
     * @type {string}
     * @memberof MovimientoPagoAllOf
     */
    'id'?: string;
    /**
     * Clave del movimiento
     * @type {string}
     * @memberof MovimientoPagoAllOf
     */
    'clave'?: string;
    /**
     * 
     * @type {FrecuenciaMovimientoPago}
     * @memberof MovimientoPagoAllOf
     */
    'frecuenciaMovimientoPago'?: FrecuenciaMovimientoPago;
    /**
     * Indica si la documentación de movimiento está completa
     * @type {boolean}
     * @memberof MovimientoPagoAllOf
     */
    'documentacionCompleta'?: boolean;
    /**
     * 
     * @type {EventoMovimientoPago}
     * @memberof MovimientoPagoAllOf
     */
    'eventoActual'?: EventoMovimientoPago;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NivelSni = {
    CANDIDATO: 'CANDIDATO',
    NIVEL_1: 'NIVEL_1',
    NIVEL_2: 'NIVEL_2',
    NIVEL_3: 'NIVEL_3',
    EMERITO: 'EMERITO'
} as const;

export type NivelSni = typeof NivelSni[keyof typeof NivelSni];


/**
 * Campos para actualizar una nómina
 * @export
 * @interface Nomina
 */
export interface Nomina {
    /**
     * Mes de cálculo de la nómina
     * @type {number}
     * @memberof Nomina
     */
    'mes': number;
    /**
     * Año de cálculo de la nómina
     * @type {number}
     * @memberof Nomina
     */
    'anio': number;
    /**
     * Descripción de la nómina
     * @type {string}
     * @memberof Nomina
     */
    'descripcion': string;
    /**
     * 
     * @type {TipoNomina}
     * @memberof Nomina
     */
    'tipo_nomina': TipoNomina;
    /**
     * 
     * @type {Array<EventoNomina>}
     * @memberof Nomina
     */
    'eventos_nomina'?: Array<EventoNomina>;
    /**
     * Indica si los pagos rechazados ya fueron incluidos en la nómina
     * @type {boolean}
     * @memberof Nomina
     */
    'rechazos_incluidos'?: boolean;
    /**
     * Estatus lógico del registro de la nómina
     * @type {boolean}
     * @memberof Nomina
     */
    'activo'?: boolean;
    /**
     * ID de un NominaSample
     * @type {string}
     * @memberof Nomina
     */
    'id'?: string;
    /**
     * Clave de la nómina
     * @type {string}
     * @memberof Nomina
     */
    'clave': string;
}
/**
 * 
 * @export
 * @interface NominaAllOf
 */
export interface NominaAllOf {
    /**
     * ID de un NominaSample
     * @type {string}
     * @memberof NominaAllOf
     */
    'id'?: string;
    /**
     * Clave de la nómina
     * @type {string}
     * @memberof NominaAllOf
     */
    'clave': string;
}
/**
 * Ejemplo de un schema
 * @export
 * @interface NominaSample
 */
export interface NominaSample {
    /**
     * ID de un NominaSample
     * @type {string}
     * @memberof NominaSample
     */
    'id'?: string;
    /**
     * Nombre de un NominaSample
     * @type {string}
     * @memberof NominaSample
     */
    'nombre': string;
}
/**
 * Campos para insertar una nómina
 * @export
 * @interface NuevaNomina
 */
export interface NuevaNomina {
    /**
     * Mes de cálculo de la nómina
     * @type {number}
     * @memberof NuevaNomina
     */
    'mes': number;
    /**
     * Año de cálculo de la nómina
     * @type {number}
     * @memberof NuevaNomina
     */
    'anio': number;
    /**
     * Descripción de la nómina
     * @type {string}
     * @memberof NuevaNomina
     */
    'descripcion': string;
    /**
     * 
     * @type {TipoNomina}
     * @memberof NuevaNomina
     */
    'tipo_nomina': TipoNomina;
    /**
     * 
     * @type {Array<EventoNomina>}
     * @memberof NuevaNomina
     */
    'eventos_nomina'?: Array<EventoNomina>;
    /**
     * Indica si los pagos rechazados ya fueron incluidos en la nómina
     * @type {boolean}
     * @memberof NuevaNomina
     */
    'rechazos_incluidos'?: boolean;
    /**
     * Estatus lógico del registro de la nómina
     * @type {boolean}
     * @memberof NuevaNomina
     */
    'activo'?: boolean;
}
/**
 * Información del un nuevo encabezado de los padrones generados en expediente persona
 * @export
 * @interface NuevoEncabezadoPadronNomina
 */
export interface NuevoEncabezadoPadronNomina {
    /**
     * Id del encabezado de padrón generado en expediente persona
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'idEncabezadoPadron'?: string;
    /**
     * 
     * @type {Nomina}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'nomina'?: Nomina;
    /**
     * Fecha de generación de la carga
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'fechaGeneracion'?: string;
    /**
     * Cvu del responsable de la generación de carga
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'cvu'?: string;
    /**
     * rfc
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'rfc'?: string;
    /**
     * Clave bancaria estandarizada
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'clabe'?: string;
    /**
     * Nombre del responsable de la generación de carga
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'nombre'?: string;
    /**
     * Apellido paterno del responsable de la generación de carga
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'apellidoPaterno'?: string;
    /**
     * Apellido materno del responsable de la generación de carga
     * @type {string}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'apellidoMaterno'?: string;
    /**
     * Cantidad de ayudantes en la generación de la carga
     * @type {number}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'cantidadAyudantes'?: number;
    /**
     * Cantidad de investigadores en la generación de la carga
     * @type {number}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'cantidadInvestigadores'?: number;
    /**
     * Cantidad total de receptores en la generación de la carga
     * @type {number}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'total'?: number;
    /**
     * Cantidad total de receptores en la generación de la carga
     * @type {number}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'cantidadPagosProcesados'?: number;
    /**
     * Este es el porcentaje que se tomará de su monto pago para deducción del adeudo que tenga.
     * @type {number}
     * @memberof NuevoEncabezadoPadronNomina
     */
    'porcentajeAdeudo'?: number;
}
/**
 * Información de un nuevo movimiento de pago
 * @export
 * @interface NuevoMovimientoPago
 */
export interface NuevoMovimientoPago {
    /**
     * Clave del movimiento
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'clave'?: string;
    /**
     * Justificación del movimiento
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'justificacion'?: string;
    /**
     * Monto del movimiento
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'monto'?: number;
    /**
     * Porcentaje del pago que implica el movimiento
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'porcentaje'?: number;
    /**
     * Fecha en que inicia el movimiento programado
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'inicio'?: string;
    /**
     * Fecha en que finaliza el movimiento programado
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'fin'?: string;
    /**
     * Monto total del movimiento programado
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'montoTotal'?: number;
    /**
     * Monto aplicado del movimiento programado
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'montoAplicado'?: number;
    /**
     * Monto remanente del movimiento programado
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'montoRemanente'?: number;
    /**
     * Cantidad de umas del movimiento
     * @type {number}
     * @memberof NuevoMovimientoPago
     */
    'cantidadUmas'?: number;
    /**
     * Clave del detalle del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'claveDetalle'?: string;
    /**
     * Descripción del detalle del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'descripcionDetalle'?: string;
    /**
     * Clave de la categoría del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'claveCategoria'?: string;
    /**
     * Descripción de la categoría del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'descripcionCategoria'?: string;
    /**
     * Clave del tipo del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'claveTipo'?: string;
    /**
     * Descripción del tipo del movimiento de pago
     * @type {string}
     * @memberof NuevoMovimientoPago
     */
    'descripcionTipo'?: string;
    /**
     * 
     * @type {Archivo}
     * @memberof NuevoMovimientoPago
     */
    'archivo'?: Archivo;
    /**
     * 
     * @type {Array<EventoMovimientoPago>}
     * @memberof NuevoMovimientoPago
     */
    'eventosMovimientoPago'?: Array<EventoMovimientoPago>;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof NuevoMovimientoPago
     */
    'persona'?: ResumenPersona;
    /**
     * 
     * @type {InformacionArchivo}
     * @memberof NuevoMovimientoPago
     */
    'informacionArchivo'?: InformacionArchivo;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof NuevoMovimientoPago
     */
    'activo'?: boolean;
    /**
     * Indica si se trata de un movimiento adicional
     * @type {boolean}
     * @memberof NuevoMovimientoPago
     */
    'adicional'?: boolean;
    /**
     * 
     * @type {FrecuenciaMovimientoPago}
     * @memberof NuevoMovimientoPago
     */
    'frecuenciaMovimientoPago'?: FrecuenciaMovimientoPago;
    /**
     * 
     * @type {CategoriaMovimientoPago}
     * @memberof NuevoMovimientoPago
     */
    'categoriaMovimientoPago'?: CategoriaMovimientoPago;
    /**
     * Indica si el usuario confirmó haber cargado el sustento documental del movimiento adicional
     * @type {boolean}
     * @memberof NuevoMovimientoPago
     */
    'documentacionCompleta'?: boolean;
    /**
     * 
     * @type {DetalleMovimientoPago}
     * @memberof NuevoMovimientoPago
     */
    'detalleMovimientoPago'?: DetalleMovimientoPago;
    /**
     * 
     * @type {Array<PagoMovimientoPago>}
     * @memberof NuevoMovimientoPago
     */
    'pagoMovimientoPago'?: Array<PagoMovimientoPago>;
    /**
     * 
     * @type {Array<AsignacionPersona>}
     * @memberof NuevoMovimientoPago
     */
    'asignacionPersona'?: Array<AsignacionPersona>;
}
/**
 * Información de un nuevo pago
 * @export
 * @interface NuevoPago
 */
export interface NuevoPago {
    /**
     * Monto calculado en el proceso de prenómina
     * @type {number}
     * @memberof NuevoPago
     */
    'montoCalculado'?: number;
    /**
     * Sumatoria de la percepciones
     * @type {number}
     * @memberof NuevoPago
     */
    'montoPercepciones'?: number;
    /**
     * Sumatoria de las deducciones
     * @type {number}
     * @memberof NuevoPago
     */
    'montoDeducciones'?: number;
    /**
     * MontoCalculado + montoPercepciones - montoDeducciones
     * @type {number}
     * @memberof NuevoPago
     */
    'montoPago'?: number;
    /**
     * 
     * @type {TipoReceptor}
     * @memberof NuevoPago
     */
    'tipoReceptor'?: TipoReceptor;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof NuevoPago
     */
    'persona'?: ResumenPersona;
    /**
     * 
     * @type {NivelSni}
     * @memberof NuevoPago
     */
    'nivelSni'?: NivelSni;
    /**
     * 
     * @type {Adeudo}
     * @memberof NuevoPago
     */
    'adeudo'?: Adeudo;
    /**
     * Contador de pagos
     * @type {number}
     * @memberof NuevoPago
     */
    'total'?: number;
    /**
     * Fecha de inicio del pago
     * @type {string}
     * @memberof NuevoPago
     */
    'inicioPago'?: string;
    /**
     * Fecha de fin del pago
     * @type {string}
     * @memberof NuevoPago
     */
    'finPago'?: string;
    /**
     * 
     * @type {Archivo}
     * @memberof NuevoPago
     */
    'archivoConciliacion'?: Archivo;
    /**
     * 
     * @type {Pago}
     * @memberof NuevoPago
     */
    'pagoOrigen'?: Pago;
    /**
     * 
     * @type {Archivo}
     * @memberof NuevoPago
     */
    'archivo'?: Archivo;
    /**
     * 
     * @type {Nomina}
     * @memberof NuevoPago
     */
    'nomina'?: Nomina;
    /**
     * 
     * @type {Array<PeriodoUma>}
     * @memberof NuevoPago
     */
    'periodosUmas'?: Array<PeriodoUma>;
    /**
     * 
     * @type {Array<EventoPago>}
     * @memberof NuevoPago
     */
    'eventosPago'?: Array<EventoPago>;
    /**
     * Indicador para identificar si el pago ya se encuentra pagado
     * @type {boolean}
     * @memberof NuevoPago
     */
    'pagado'?: boolean;
    /**
     * Indicador para la conciliación del pago
     * @type {boolean}
     * @memberof NuevoPago
     */
    'reportado'?: boolean;
    /**
     * 
     * @type {Receptor}
     * @memberof NuevoPago
     */
    'receptor'?: Receptor;
    /**
     * Clave de la nómina inicial del pago
     * @type {string}
     * @memberof NuevoPago
     */
    'claveNominaInicial'?: string;
    /**
     * Indicador de estatus de validación de reglas de expediente persona
     * @type {boolean}
     * @memberof NuevoPago
     */
    'estatusValidacionReglas'?: boolean;
    /**
     * Indicador de pago generado por inclusión de pago
     * @type {boolean}
     * @memberof NuevoPago
     */
    'inclusionPago'?: boolean;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof NuevoPago
     */
    'activo'?: boolean;
    /**
     * Indicador de adeudo fue actualizado en apeiron.
     * @type {boolean}
     * @memberof NuevoPago
     */
    'adeudoExternoActualizado'?: boolean;
}
/**
 * Opción de menú
 * @export
 * @interface OpcionMenu
 */
export interface OpcionMenu {
    /**
     * Valor de la opción del menú
     * @type {number}
     * @memberof OpcionMenu
     */
    'value'?: number;
    /**
     * Texto de la opción del menú
     * @type {string}
     * @memberof OpcionMenu
     */
    'text'?: string;
}
/**
 * Opción de menú
 * @export
 * @interface OpcionMenuFiltro
 */
export interface OpcionMenuFiltro {
    /**
     * Valor de la opción del menú
     * @type {string}
     * @memberof OpcionMenuFiltro
     */
    'value'?: string;
    /**
     * Texto de la opción del menú
     * @type {string}
     * @memberof OpcionMenuFiltro
     */
    'text'?: string;
}
/**
 * Información de pago
 * @export
 * @interface Pago
 */
export interface Pago {
    /**
     * Monto calculado en el proceso de prenómina
     * @type {number}
     * @memberof Pago
     */
    'montoCalculado'?: number;
    /**
     * Sumatoria de la percepciones
     * @type {number}
     * @memberof Pago
     */
    'montoPercepciones'?: number;
    /**
     * Sumatoria de las deducciones
     * @type {number}
     * @memberof Pago
     */
    'montoDeducciones'?: number;
    /**
     * MontoCalculado + montoPercepciones - montoDeducciones
     * @type {number}
     * @memberof Pago
     */
    'montoPago'?: number;
    /**
     * 
     * @type {TipoReceptor}
     * @memberof Pago
     */
    'tipoReceptor'?: TipoReceptor;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof Pago
     */
    'persona'?: ResumenPersona;
    /**
     * 
     * @type {NivelSni}
     * @memberof Pago
     */
    'nivelSni'?: NivelSni;
    /**
     * 
     * @type {Adeudo}
     * @memberof Pago
     */
    'adeudo'?: Adeudo;
    /**
     * Contador de pagos
     * @type {number}
     * @memberof Pago
     */
    'total'?: number;
    /**
     * Fecha de inicio del pago
     * @type {string}
     * @memberof Pago
     */
    'inicioPago'?: string;
    /**
     * Fecha de fin del pago
     * @type {string}
     * @memberof Pago
     */
    'finPago'?: string;
    /**
     * 
     * @type {Archivo}
     * @memberof Pago
     */
    'archivoConciliacion'?: Archivo;
    /**
     * 
     * @type {Pago}
     * @memberof Pago
     */
    'pagoOrigen'?: Pago;
    /**
     * 
     * @type {Archivo}
     * @memberof Pago
     */
    'archivo'?: Archivo;
    /**
     * 
     * @type {Nomina}
     * @memberof Pago
     */
    'nomina'?: Nomina;
    /**
     * 
     * @type {Array<PeriodoUma>}
     * @memberof Pago
     */
    'periodosUmas'?: Array<PeriodoUma>;
    /**
     * 
     * @type {Array<EventoPago>}
     * @memberof Pago
     */
    'eventosPago'?: Array<EventoPago>;
    /**
     * Indicador para identificar si el pago ya se encuentra pagado
     * @type {boolean}
     * @memberof Pago
     */
    'pagado'?: boolean;
    /**
     * Indicador para la conciliación del pago
     * @type {boolean}
     * @memberof Pago
     */
    'reportado'?: boolean;
    /**
     * 
     * @type {Receptor}
     * @memberof Pago
     */
    'receptor'?: Receptor;
    /**
     * Clave de la nómina inicial del pago
     * @type {string}
     * @memberof Pago
     */
    'claveNominaInicial'?: string;
    /**
     * Indicador de estatus de validación de reglas de expediente persona
     * @type {boolean}
     * @memberof Pago
     */
    'estatusValidacionReglas'?: boolean;
    /**
     * Indicador de pago generado por inclusión de pago
     * @type {boolean}
     * @memberof Pago
     */
    'inclusionPago'?: boolean;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof Pago
     */
    'activo'?: boolean;
    /**
     * Indicador de adeudo fue actualizado en apeiron.
     * @type {boolean}
     * @memberof Pago
     */
    'adeudoExternoActualizado'?: boolean;
    /**
     * Id de un pago
     * @type {string}
     * @memberof Pago
     */
    'id'?: string;
    /**
     * 
     * @type {EventoPago}
     * @memberof Pago
     */
    'eventoActual'?: EventoPago;
}
/**
 * 
 * @export
 * @interface PagoAllOf
 */
export interface PagoAllOf {
    /**
     * Id de un pago
     * @type {string}
     * @memberof PagoAllOf
     */
    'id'?: string;
    /**
     * 
     * @type {EventoPago}
     * @memberof PagoAllOf
     */
    'eventoActual'?: EventoPago;
}
/**
 * Colección transitiva entre pago y movimiento_pago
 * @export
 * @interface PagoMovimientoPago
 */
export interface PagoMovimientoPago {
    /**
     * Id de un pago movimiento pago
     * @type {string}
     * @memberof PagoMovimientoPago
     */
    'id'?: string;
    /**
     * 
     * @type {Nomina}
     * @memberof PagoMovimientoPago
     */
    'nomina'?: Nomina;
    /**
     * 
     * @type {Pago}
     * @memberof PagoMovimientoPago
     */
    'pago'?: Pago;
    /**
     * 
     * @type {MovimientoPago}
     * @memberof PagoMovimientoPago
     */
    'movimientoPago'?: MovimientoPago;
    /**
     * Fecha en que inicia el cálculo del movimiento adicional
     * @type {string}
     * @memberof PagoMovimientoPago
     */
    'inicioCalculo'?: string;
    /**
     * Fecha en que finaliza el cálculo del movimiento adicional
     * @type {string}
     * @memberof PagoMovimientoPago
     */
    'finCalculo'?: string;
    /**
     * Monto de cálculo del movimiento adicional
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'monto'?: number;
    /**
     * Porcentaje del pago que implica el movimiento adicional
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'porcentaje'?: number;
    /**
     * Cantidad de UMAS que implica el movimiento adicional
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'cantidadUmas'?: number;
    /**
     * Monto del movimiento a aplicar al pago
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'montoAplicar'?: number;
    /**
     * Monto del movimiento aplicado al pago
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'montoAplicado'?: number;
    /**
     * Monto del movimiento no aplicado al pago
     * @type {number}
     * @memberof PagoMovimientoPago
     */
    'montoNoAplicado'?: number;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof PagoMovimientoPago
     */
    'activo'?: boolean;
}
/**
 * Reporte de pagos de una nómina
 * @export
 * @interface PagosConciliados
 */
export interface PagosConciliados {
    /**
     * 
     * @type {TipoReceptor}
     * @memberof PagosConciliados
     */
    'tipo_receptor'?: TipoReceptor;
    /**
     * Monto de pago
     * @type {number}
     * @memberof PagosConciliados
     */
    'monto_pago'?: number;
    /**
     * Estado del pago
     * @type {string}
     * @memberof PagosConciliados
     */
    'estatus'?: string;
    /**
     * 
     * @type {ResumenPersona}
     * @memberof PagosConciliados
     */
    'persona'?: ResumenPersona;
}
/**
 * Total de pagos reportados y pendientes de reportar
 * @export
 * @interface PagosReportadosTotal
 */
export interface PagosReportadosTotal {
    /**
     * total de pagos reportados
     * @type {number}
     * @memberof PagosReportadosTotal
     */
    'reportados'?: number;
    /**
     * total de pagos pendientes de reportar
     * @type {number}
     * @memberof PagosReportadosTotal
     */
    'pendientesReportar'?: number;
}
/**
 * Periodos del valor de las Umas
 * @export
 * @interface PeriodoUma
 */
export interface PeriodoUma {
    /**
     * Valor mensual en pesos mexicanos de la UMA con la que se calculó el pago
     * @type {number}
     * @memberof PeriodoUma
     */
    'uma'?: number;
    /**
     * Fecha inicial del período de valor uma
     * @type {string}
     * @memberof PeriodoUma
     */
    'inicio'?: string;
    /**
     * Fecha final del período de valor uma
     * @type {string}
     * @memberof PeriodoUma
     */
    'fin'?: string;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    'type'?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    'title'?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    'status'?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    'detail'?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    'instance'?: string;
}
/**
 * Información común de los receptores de pago
 * @export
 * @interface Receptor
 */
export interface Receptor {
    /**
     * Fecha de inicio de la vigencia
     * @type {string}
     * @memberof Receptor
     */
    'inicioVigencia'?: string;
    /**
     * Fecha de fin de la vigencia
     * @type {string}
     * @memberof Receptor
     */
    'finVigencia'?: string;
    /**
     * Referencia al investigador
     * @type {number}
     * @memberof Receptor
     */
    'cvuInvestigador'?: number;
    /**
     * Referencia al investigador
     * @type {number}
     * @memberof Receptor
     */
    'expedienteInvestigador'?: number;
    /**
     * Nombre de la entidad federativa
     * @type {string}
     * @memberof Receptor
     */
    'localizacion'?: string;
    /**
     * Clave bancaria estandarizada
     * @type {string}
     * @memberof Receptor
     */
    'clabe'?: string;
    /**
     * Fecha de extracción de la información
     * @type {string}
     * @memberof Receptor
     */
    'fechaExtraccion'?: string;
    /**
     * 
     * @type {Beneficiario}
     * @memberof Receptor
     */
    'beneficiario'?: Beneficiario;
    /**
     * 
     * @type {Investigador}
     * @memberof Receptor
     */
    'investigador'?: Investigador;
    /**
     * 
     * @type {Ayudante}
     * @memberof Receptor
     */
    'ayudante'?: Ayudante;
    /**
     * Cvu de la persona
     * @type {string}
     * @memberof Receptor
     */
    'cvu'?: string;
    /**
     * Curp de la persona
     * @type {string}
     * @memberof Receptor
     */
    'curp'?: string;
    /**
     * Nombre de la persona
     * @type {string}
     * @memberof Receptor
     */
    'nombre'?: string;
    /**
     * Apellido paterno de la persona
     * @type {string}
     * @memberof Receptor
     */
    'apellido_paterno'?: string;
    /**
     * Apellido materno de la persona
     * @type {string}
     * @memberof Receptor
     */
    'apellido_materno'?: string;
}
/**
 * Reporte regional de una nómina
 * @export
 * @interface ReporteRegional
 */
export interface ReporteRegional {
    /**
     * Monto de pago
     * @type {number}
     * @memberof ReporteRegional
     */
    'monto_pago'?: number;
    /**
     * Localización del receptor de un pago
     * @type {string}
     * @memberof ReporteRegional
     */
    'localizacion'?: string;
    /**
     * 
     * @type {Array<ResumenPagos>}
     * @memberof ReporteRegional
     */
    'receptor'?: Array<ResumenPagos>;
}
/**
 * Agrupación de lo totales de pagos
 * @export
 * @interface ResumenPagos
 */
export interface ResumenPagos {
    /**
     * 
     * @type {TipoReceptor}
     * @memberof ResumenPagos
     */
    'tipo_receptor'?: TipoReceptor;
    /**
     * Total por tipo de receptor
     * @type {number}
     * @memberof ResumenPagos
     */
    'total_tipo_receptor'?: number;
    /**
     * Total de los montos de pago
     * @type {number}
     * @memberof ResumenPagos
     */
    'monto_pago'?: number;
}
/**
 * Agrupación de lo totales de pagos conciliados
 * @export
 * @interface ResumenPagosConciliados
 */
export interface ResumenPagosConciliados {
    /**
     * 
     * @type {TipoReceptor}
     * @memberof ResumenPagosConciliados
     */
    'tipo_receptor'?: TipoReceptor;
    /**
     * Total por tipo de receptor
     * @type {number}
     * @memberof ResumenPagosConciliados
     */
    'total_tipo_receptor'?: number;
    /**
     * total de pagos aceptados
     * @type {number}
     * @memberof ResumenPagosConciliados
     */
    'pagado'?: number;
    /**
     * total de pagos rechazados
     * @type {number}
     * @memberof ResumenPagosConciliados
     */
    'rechazado'?: number;
    /**
     * total de pagos reportados
     * @type {number}
     * @memberof ResumenPagosConciliados
     */
    'reportado'?: number;
    /**
     * total de pagos excluidos
     * @type {number}
     * @memberof ResumenPagosConciliados
     */
    'excluido'?: number;
}
/**
 * Resumen de una persona
 * @export
 * @interface ResumenPersona
 */
export interface ResumenPersona {
    /**
     * Id de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'id'?: string;
    /**
     * Cvu de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'cvu'?: string;
    /**
     * Curp de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'curp'?: string;
    /**
     * rfc de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'rfc'?: string;
    /**
     * Nombre de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'nombre'?: string;
    /**
     * Apellido paterno de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'apellido_paterno'?: string;
    /**
     * Apellido materno de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'apellido_materno'?: string;
    /**
     * Nombre completo de la persona
     * @type {string}
     * @memberof ResumenPersona
     */
    'nombre_completo'?: string;
}
/**
 * Información de la solicitud de dispersión generada en gestor de pagos
 * @export
 * @interface SolicitudDispersion
 */
export interface SolicitudDispersion {
    /**
     * Id de solicitud dispersión
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'id'?: string;
    /**
     * Id generado en gestor de pagos (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'idSolicitudGenerado'?: string;
    /**
     * Clave de origen (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'idOrigen'?: string;
    /**
     * Id de la convocatoria (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'idConvocatoria'?: string;
    /**
     * Id de la nómina (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'idCalendarioNomina'?: string;
    /**
     * Clave de la nómina (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'claveCalendarioNomina'?: string;
    /**
     * Clave de la subdirección (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'subdireccion'?: string;
    /**
     * Total de transacciones de pagos de la solicitud (Campo gestor de pagos)
     * @type {number}
     * @memberof SolicitudDispersion
     */
    'pagosEsperados'?: number;
    /**
     * Estatus de la solicitud de dispersión (Campo gestor de pagos)
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'estatus'?: string;
    /**
     * Total de transacciones de pagos cargadas (Campo gestor de pagos)
     * @type {number}
     * @memberof SolicitudDispersion
     */
    'cargados'?: number;
    /**
     * Monto total de las transacciones de pagos cargadas (Campo gestor de pagos)
     * @type {number}
     * @memberof SolicitudDispersion
     */
    'montoPago'?: number;
    /**
     * Monto total de las transacciones de la solicitud de dispersión
     * @type {number}
     * @memberof SolicitudDispersion
     */
    'montoTotalTransacciones'?: number;
    /**
     * 
     * @type {Nomina}
     * @memberof SolicitudDispersion
     */
    'nomina'?: Nomina;
    /**
     * Indicador de cargando pagos al gestor de pagos
     * @type {boolean}
     * @memberof SolicitudDispersion
     */
    'cargandoPagos'?: boolean;
    /**
     * JobId de la tarea ejecutada en segundo plano para generar las transacciones
     * @type {string}
     * @memberof SolicitudDispersion
     */
    'jobIdGenerandoTransacciones'?: string;
    /**
     * Indica si se están generando las transacciones de pago
     * @type {boolean}
     * @memberof SolicitudDispersion
     */
    'generandoTransacciones'?: boolean;
    /**
     * Indica si se está aceptando la solicitud
     * @type {boolean}
     * @memberof SolicitudDispersion
     */
    'aceptandoSolicitud'?: boolean;
}
/**
 * Tipo de archivo
 * @export
 * @enum {string}
 */

export const TipoArchivo = {
    DISPERSION: 'DISPERSION',
    CONCILIACION: 'CONCILIACION',
    SUSTENTO_MOVIMIENTO: 'SUSTENTO_MOVIMIENTO',
    SUSTENTO_DESACTIVAR_MOVIMIENTO: 'SUSTENTO_DESACTIVAR_MOVIMIENTO'
} as const;

export type TipoArchivo = typeof TipoArchivo[keyof typeof TipoArchivo];


/**
 * Catálogo de tipos de movimientos de pago
 * @export
 * @interface TipoMovimientoPago
 */
export interface TipoMovimientoPago {
    /**
     * Id de tipo movimiento
     * @type {string}
     * @memberof TipoMovimientoPago
     */
    'id'?: string;
    /**
     * Clave del tipo de movimiento
     * @type {string}
     * @memberof TipoMovimientoPago
     */
    'clave'?: string;
    /**
     * Descripción del tipo de movimiento
     * @type {string}
     * @memberof TipoMovimientoPago
     */
    'descripcion'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof TipoMovimientoPago
     */
    'activo'?: boolean;
}
/**
 * Tipo de nómina
 * @export
 * @enum {string}
 */

export const TipoNomina = {
    ORDINARIA: 'ORDINARIA',
    EXTRAORDINARIA: 'EXTRAORDINARIA'
} as const;

export type TipoNomina = typeof TipoNomina[keyof typeof TipoNomina];


/**
 * 
 * @export
 * @enum {string}
 */

export const TipoReceptor = {
    INVESTIGADOR: 'INVESTIGADOR',
    AYUDANTE: 'AYUDANTE',
    PENSIONISTA: 'PENSIONISTA',
    BENEFICIARIO: 'BENEFICIARIO'
} as const;

export type TipoReceptor = typeof TipoReceptor[keyof typeof TipoReceptor];


/**
 * Total de movimientos adicionales documentados y pendientes de documentar
 * @export
 * @interface TotalMovimientosDocumentados
 */
export interface TotalMovimientosDocumentados {
    /**
     * total de movimientos adicionales documentados
     * @type {number}
     * @memberof TotalMovimientosDocumentados
     */
    'documentados'?: number;
    /**
     * total de movimientos adicionales pendientes de documentar
     * @type {number}
     * @memberof TotalMovimientosDocumentados
     */
    'pendientesDocumentar'?: number;
}
/**
 * Información de las transacciones
 * @export
 * @interface TransaccionPago
 */
export interface TransaccionPago {
    /**
     * Id de transaccion pago
     * @type {string}
     * @memberof TransaccionPago
     */
    'id'?: string;
    /**
     * 
     * @type {SolicitudDispersion}
     * @memberof TransaccionPago
     */
    'solicitudDispersion'?: SolicitudDispersion;
    /**
     * Indicador para la conciliación del pago
     * @type {boolean}
     * @memberof TransaccionPago
     */
    'reportado'?: boolean;
    /**
     * Id generado en gestor de pagos (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idPagoGenerado'?: string;
    /**
     * Clave de origen (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idOrigen'?: string;
    /**
     * Id de la convocatoria (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idConvocatoria'?: string;
    /**
     * Descripción de la convocatoria (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'descrConvocatoria'?: string;
    /**
     * Id del apoyo (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idApoyo'?: string;
    /**
     * Id de la nómina (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idCalendarioNomina'?: string;
    /**
     * Clave de la nómina (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'claveCalendarioNomina'?: string;
    /**
     * Id del beneficiario o CVU (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'idBeneficiario'?: string;
    /**
     * Clave de registro único de población (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'curp'?: string;
    /**
     * Nombres de la persona receptora de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'nombres'?: string;
    /**
     * Apellido paterno de la persona receptora de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'apellidoPaterno'?: string;
    /**
     * Apellido materno de la persona receptora de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'apellidoMaterno'?: string;
    /**
     * Concepto de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'conceptoPago'?: string;
    /**
     * Clave bancaria estandarizada (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'clabeBancaria'?: string;
    /**
     * Monto del pago de la transacción (Campo gestor de pagos)
     * @type {number}
     * @memberof TransaccionPago
     */
    'montoPago'?: number;
    /**
     * Fecha inicial del periodo de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'fechaInicio'?: string;
    /**
     * Fecha final del periodo de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'fechaFin'?: string;
    /**
     * Estatus del proceso de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'estatusProcesoPago'?: string;
    /**
     * Tipo de pago (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'tipoPago'?: string;
    /**
     * Clave de la subdirección (Campo gestor de pagos)
     * @type {string}
     * @memberof TransaccionPago
     */
    'subdireccion'?: string;
    /**
     * Listado de errores en la transacción de pago (Campo gestor de pagos)
     * @type {Array<string>}
     * @memberof TransaccionPago
     */
    'errores'?: Array<string>;
}
/**
 * Información de la cantidad de UMAS asignadas por Nivel SNI
 * @export
 * @interface UmaNivel
 */
export interface UmaNivel {
    /**
     * Id de Uma nivel
     * @type {string}
     * @memberof UmaNivel
     */
    'id'?: string;
    /**
     * Cantidad de UMAS asignadas
     * @type {number}
     * @memberof UmaNivel
     */
    'cantidadUma'?: number;
    /**
     * 
     * @type {NivelSni}
     * @memberof UmaNivel
     */
    'nivelSni'?: NivelSni;
    /**
     * Fecha de vigencia del valor UMA - Nivel SNI
     * @type {string}
     * @memberof UmaNivel
     */
    'vigencia'?: string;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof UmaNivel
     */
    'activo'?: boolean;
}
/**
 * Información de la UMA y su valor por año
 * @export
 * @interface ValorUma
 */
export interface ValorUma {
    /**
     * Id de valor Uma
     * @type {string}
     * @memberof ValorUma
     */
    'id'?: string;
    /**
     * Año de vigencia de la UMA
     * @type {number}
     * @memberof ValorUma
     */
    'anio'?: number;
    /**
     * Valor diario en pesos mexicanos de la UMA
     * @type {number}
     * @memberof ValorUma
     */
    'valorDiario'?: number;
    /**
     * Valor mensual en pesos mexicanos de la UMA
     * @type {number}
     * @memberof ValorUma
     */
    'valorMensual'?: number;
    /**
     * Valor anual en pesos mexicanos de la UMA
     * @type {number}
     * @memberof ValorUma
     */
    'valorAnual'?: number;
    /**
     * Estatus lógico del registro
     * @type {boolean}
     * @memberof ValorUma
     */
    'activo'?: boolean;
}

/**
 * ArchivoApi - axios parameter creator
 * @export
 */
export const ArchivoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumentoDms: async (archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarDocumentoDms', 'archivo', archivo)
            const localVarPath = `/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivos: async (idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (tipoArchivo) {
                localVarQueryParameter['tipoArchivo'] = tipoArchivo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArchivoApi - functional programming interface
 * @export
 */
export const ArchivoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArchivoApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumentoDms(archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumentoDms(archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Archivo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerArchivos(idNomina, tipoArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArchivoApi - factory interface
 * @export
 */
export const ArchivoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArchivoApiFp(configuration)
    return {
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumentoDms(archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumentoDms(archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: any): AxiosPromise<Array<Archivo>> {
            return localVarFp.obtenerArchivos(idNomina, tipoArchivo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArchivoApi - object-oriented interface
 * @export
 * @class ArchivoApi
 * @extends {BaseAPI}
 */
export class ArchivoApi extends BaseAPI {
    /**
     * Elimina un archivo del Dms y su referencia en base de datos
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchivoApi
     */
    public eliminarDocumentoDms(archivo: Archivo, options?: AxiosRequestConfig) {
        return ArchivoApiFp(this.configuration).eliminarDocumentoDms(archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de los archivos por tipo asociados a una nómina
     * @param {string} [idNomina] ID de nomina
     * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArchivoApi
     */
    public obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: AxiosRequestConfig) {
        return ArchivoApiFp(this.configuration).obtenerArchivos(idNomina, tipoArchivo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AsignacionPersonaApi - axios parameter creator
 * @export
 */
export const AsignacionPersonaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAsignacionPersonaReceptorCvuInvestigador: async (cvu?: number, tipoReceptor?: TipoReceptor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asignacion-persona/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AsignacionPersonaApi - functional programming interface
 * @export
 */
export const AsignacionPersonaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AsignacionPersonaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AsignacionPersona>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AsignacionPersonaApi - factory interface
 * @export
 */
export const AsignacionPersonaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AsignacionPersonaApiFp(configuration)
    return {
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: any): AxiosPromise<Array<AsignacionPersona>> {
            return localVarFp.obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AsignacionPersonaApi - object-oriented interface
 * @export
 * @class AsignacionPersonaApi
 * @extends {BaseAPI}
 */
export class AsignacionPersonaApi extends BaseAPI {
    /**
     * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AsignacionPersonaApi
     */
    public obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig) {
        return AsignacionPersonaApiFp(this.configuration).obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CategoriaMovimientoPagoApi - axios parameter creator
 * @export
 */
export const CategoriaMovimientoPagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerCategoriasPorTipoMovimientoPago: async (idTipoMovimiento: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTipoMovimiento' is not null or undefined
            assertParamExists('obtenerCategoriasPorTipoMovimientoPago', 'idTipoMovimiento', idTipoMovimiento)
            const localVarPath = `/categoria-movimiento-pago/{idTipoMovimiento}`
                .replace(`{${"idTipoMovimiento"}}`, encodeURIComponent(String(idTipoMovimiento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriaMovimientoPagoApi - functional programming interface
 * @export
 */
export const CategoriaMovimientoPagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriaMovimientoPagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriaMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoriaMovimientoPagoApi - factory interface
 * @export
 */
export const CategoriaMovimientoPagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriaMovimientoPagoApiFp(configuration)
    return {
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: any): AxiosPromise<Array<CategoriaMovimientoPago>> {
            return localVarFp.obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoriaMovimientoPagoApi - object-oriented interface
 * @export
 * @class CategoriaMovimientoPagoApi
 * @extends {BaseAPI}
 */
export class CategoriaMovimientoPagoApi extends BaseAPI {
    /**
     * Obtiene un listado las categorías por tipo de movimiento de pago
     * @param {string} idTipoMovimiento ID tipo de movimiento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriaMovimientoPagoApi
     */
    public obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: AxiosRequestConfig) {
        return CategoriaMovimientoPagoApiFp(this.configuration).obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConciliacionApi - axios parameter creator
 * @export
 */
export const ConciliacionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conciliarNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('conciliarNomina', 'idNomina', idNomina)
            const localVarPath = `/conciliacion/conciliar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarArchivoConciliacion: async (archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarArchivoConciliacion', 'archivo', archivo)
            const localVarPath = `/conciliacion/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoConciliacion: async (idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('subirArchivoConciliacion', 'idNomina', idNomina)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivoConciliacion', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/conciliacion/subir-archivo/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConciliacionApi - functional programming interface
 * @export
 */
export const ConciliacionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConciliacionApiAxiosParamCreator(configuration)
    return {
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conciliarNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conciliarNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarArchivoConciliacion(archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarArchivoConciliacion(archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivoConciliacion(idNomina, informacionArchivo, clave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConciliacionApi - factory interface
 * @export
 */
export const ConciliacionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConciliacionApiFp(configuration)
    return {
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conciliarNomina(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.conciliarNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarArchivoConciliacion(archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarArchivoConciliacion(archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: any): AxiosPromise<string> {
            return localVarFp.subirArchivoConciliacion(idNomina, informacionArchivo, clave, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConciliacionApi - object-oriented interface
 * @export
 * @class ConciliacionApi
 * @extends {BaseAPI}
 */
export class ConciliacionApi extends BaseAPI {
    /**
     * Procesa la conciliación de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConciliacionApi
     */
    public conciliarNomina(idNomina: string, options?: AxiosRequestConfig) {
        return ConciliacionApiFp(this.configuration).conciliarNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un archivo de conciliación y actualiza los pagos reportados a false
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConciliacionApi
     */
    public eliminarArchivoConciliacion(archivo: Archivo, options?: AxiosRequestConfig) {
        return ConciliacionApiFp(this.configuration).eliminarArchivoConciliacion(archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valida el archivo de conciliación para subirlo al repositorio DMS
     * @param {string} idNomina ID de la nomina
     * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
     * @param {string} [clave] Clave de la nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConciliacionApi
     */
    public subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: AxiosRequestConfig) {
        return ConciliacionApiFp(this.configuration).subirArchivoConciliacion(idNomina, informacionArchivo, clave, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DetalleMovimientoPagoApi - axios parameter creator
 * @export
 */
export const DetalleMovimientoPagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetallePorCategoriaMovimientoPago: async (idCategoriaMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idCategoriaMovimientoPago' is not null or undefined
            assertParamExists('obtenerDetallePorCategoriaMovimientoPago', 'idCategoriaMovimientoPago', idCategoriaMovimientoPago)
            const localVarPath = `/detalle-movimiento-pago/{idCategoriaMovimientoPago}`
                .replace(`{${"idCategoriaMovimientoPago"}}`, encodeURIComponent(String(idCategoriaMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DetalleMovimientoPagoApi - functional programming interface
 * @export
 */
export const DetalleMovimientoPagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DetalleMovimientoPagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetalleMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DetalleMovimientoPagoApi - factory interface
 * @export
 */
export const DetalleMovimientoPagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DetalleMovimientoPagoApiFp(configuration)
    return {
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: any): AxiosPromise<Array<DetalleMovimientoPago>> {
            return localVarFp.obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DetalleMovimientoPagoApi - object-oriented interface
 * @export
 * @class DetalleMovimientoPagoApi
 * @extends {BaseAPI}
 */
export class DetalleMovimientoPagoApi extends BaseAPI {
    /**
     * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
     * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DetalleMovimientoPagoApi
     */
    public obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: AxiosRequestConfig) {
        return DetalleMovimientoPagoApiFp(this.configuration).obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EncabezadoPadronPrenominaApi - axios parameter creator
 * @export
 */
export const EncabezadoPadronPrenominaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEncabezadoPadronNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerEncabezadoPadronNomina', 'idNomina', idNomina)
            const localVarPath = `/encabezado-padron-nomina/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EncabezadoPadronPrenominaApi - functional programming interface
 * @export
 */
export const EncabezadoPadronPrenominaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EncabezadoPadronPrenominaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEncabezadoPadronNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EncabezadoPadronNomina>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEncabezadoPadronNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EncabezadoPadronPrenominaApi - factory interface
 * @export
 */
export const EncabezadoPadronPrenominaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EncabezadoPadronPrenominaApiFp(configuration)
    return {
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEncabezadoPadronNomina(idNomina: string, options?: any): AxiosPromise<EncabezadoPadronNomina> {
            return localVarFp.obtenerEncabezadoPadronNomina(idNomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EncabezadoPadronPrenominaApi - object-oriented interface
 * @export
 * @class EncabezadoPadronPrenominaApi
 * @extends {BaseAPI}
 */
export class EncabezadoPadronPrenominaApi extends BaseAPI {
    /**
     * Obtiene el encabezado del padrón de nómina asociado a la nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EncabezadoPadronPrenominaApi
     */
    public obtenerEncabezadoPadronNomina(idNomina: string, options?: AxiosRequestConfig) {
        return EncabezadoPadronPrenominaApiFp(this.configuration).obtenerEncabezadoPadronNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventoApi - axios parameter creator
 * @export
 */
export const EventoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEventoNominaPago: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('actualizarEventoNominaPago', 'idNomina', idNomina)
            const localVarPath = `/evento/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventoApi - functional programming interface
 * @export
 */
export const EventoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventoApiAxiosParamCreator(configuration)
    return {
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarEventoNominaPago(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarEventoNominaPago(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventoApi - factory interface
 * @export
 */
export const EventoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventoApiFp(configuration)
    return {
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEventoNominaPago(idNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarEventoNominaPago(idNomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventoApi - object-oriented interface
 * @export
 * @class EventoApi
 * @extends {BaseAPI}
 */
export class EventoApi extends BaseAPI {
    /**
     * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventoApi
     */
    public actualizarEventoNominaPago(idNomina: string, options?: AxiosRequestConfig) {
        return EventoApiFp(this.configuration).actualizarEventoNominaPago(idNomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstadoJob: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('obtenerEstadoJob', 'jobId', jobId)
            const localVarPath = `/job/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEstadoJob(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEstadoJob(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobApiFp(configuration)
    return {
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstadoJob(jobId: string, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerEstadoJob(jobId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     * Obtiene el estado de un job
     * @param {string} jobId ID del job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public obtenerEstadoJob(jobId: string, options?: AxiosRequestConfig) {
        return JobApiFp(this.configuration).obtenerEstadoJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MovimientoAdicionalApi - axios parameter creator
 * @export
 */
export const MovimientoAdicionalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarDocumentacionCompleta: async (idMovimientoPago: string, documentacionMovimiento?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('actualizarDocumentacionCompleta', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (documentacionMovimiento !== undefined) {
                localVarQueryParameter['documentacionMovimiento'] = documentacionMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento: async (idMovimientoPago: string, archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('eliminarDocumento', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarDocumento', 'archivo', archivo)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoAdicional: async (nuevoMovimientoPago: NuevoMovimientoPago, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoMovimientoPago' is not null or undefined
            assertParamExists('insertarMovimientoAdicional', 'nuevoMovimientoPago', nuevoMovimientoPago)
            const localVarPath = `/movimiento-adicional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoMovimientoPago, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetalleMovimientoAdicional: async (idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerDetalleMovimientoAdicional', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosAdicionalesFiltrados: async (idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (tipoMovimiento !== undefined) {
                localVarQueryParameter['tipoMovimiento'] = tipoMovimiento;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }

            if (detalleMovimientoPago !== undefined) {
                localVarQueryParameter['detalleMovimientoPago'] = detalleMovimientoPago;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (frecuenciaMovimientoPago !== undefined) {
                localVarQueryParameter['frecuenciaMovimientoPago'] = frecuenciaMovimientoPago;
            }

            if (documentacionMovimiento !== undefined) {
                localVarQueryParameter['documentacionMovimiento'] = documentacionMovimiento;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPaginaDeArchivos: async (idMovimientoPago: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerPaginaDeArchivos', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerValidacionPuedeActualizarArchivos: async (idMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerValidacionPuedeActualizarArchivos', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/puede-actualizar-archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivos: async (idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('subirArchivos', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivos', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovimientoAdicionalApi - functional programming interface
 * @export
 */
export const MovimientoAdicionalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovimientoAdicionalApiAxiosParamCreator(configuration)
    return {
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumento(idMovimientoPago, archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertarMovimientoAdicional(nuevoMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Archivo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPaginaDeArchivos(idMovimientoPago, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivos(idMovimientoPago, informacionArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MovimientoAdicionalApi - factory interface
 * @export
 */
export const MovimientoAdicionalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovimientoAdicionalApiFp(configuration)
    return {
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumento(idMovimientoPago, archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: any): AxiosPromise<string> {
            return localVarFp.insertarMovimientoAdicional(nuevoMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: any): AxiosPromise<Array<Archivo>> {
            return localVarFp.obtenerPaginaDeArchivos(idMovimientoPago, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: any): AxiosPromise<void> {
            return localVarFp.subirArchivos(idMovimientoPago, informacionArchivo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovimientoAdicionalApi - object-oriented interface
 * @export
 * @class MovimientoAdicionalApi
 * @extends {BaseAPI}
 */
export class MovimientoAdicionalApi extends BaseAPI {
    /**
     * Actualiza documentación completa del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un archivo del Dms y su referencia en base de datos
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).eliminarDocumento(idMovimientoPago, archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Inserta un movimiento adicional
     * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).insertarMovimientoAdicional(nuevoMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el detalle del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {string} [idNomina] ID de nomina
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos adicionales filtrados
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [tipoMovimiento] Tipo de movimiento
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
     * @param {string} [clave] Clave del movimiento
     * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
     * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * consulta una página de los documentos del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).obtenerPaginaDeArchivos(idMovimientoPago, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta una validación booleana para saber si se permite realizar actualización de documentos
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sube documentos al DMS y guarda la referencia en archivo
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoAdicionalApi
     */
    public subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: AxiosRequestConfig) {
        return MovimientoAdicionalApiFp(this.configuration).subirArchivos(idMovimientoPago, informacionArchivo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MovimientoPagoApi - axios parameter creator
 * @export
 */
export const MovimientoPagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicar: async (idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('calcularMontoAplicar', 'idPago', idPago)
            const localVarPath = `/movimiento-pago/monto-aplicar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }

            if (monto !== undefined) {
                localVarQueryParameter['monto'] = monto;
            }

            if (porcentaje !== undefined) {
                localVarQueryParameter['porcentaje'] = porcentaje;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarFechas: async (idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('calcularMontoAplicarFechas', 'idPago', idPago)
            const localVarPath = `/movimiento-pago/monto-aplicar-fechas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarPeriodoUmas: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-pago/monto-aplicar-periodo-umas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAproximado: async (nivelSni?: NivelSni, localizacion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-pago/calcular-aproximado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoBeneficiario: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/calcular-monto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (meses !== undefined) {
                localVarQueryParameter['meses'] = meses;
            }

            if (porcentaje !== undefined) {
                localVarQueryParameter['porcentaje'] = porcentaje;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoIncapacidadPermanente: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/incapacidad-permanente/calcular-monto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosActivosPorCategoriaDetalle: async (cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/activos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }

            if (claveDetalleMovimiento !== undefined) {
                localVarQueryParameter['claveDetalleMovimiento'] = claveDetalleMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosVigentesPorCategoria: async (cvu?: number, claveCategoriaMovimiento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-programado/vigentes-categoria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosBeneficiariosPorInvestigador: async (cvu?: number, idMovimientoPago?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/buscar-beneficiarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (idMovimientoPago !== undefined) {
                localVarQueryParameter['idMovimientoPago'] = idMovimientoPago;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarExistePagosDemasiaInvestigador: async (cvu?: number, fechas?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/pagos-demasia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificarMovimientosPendientesPorAplicar: async (idNomina: string, mes?: number, anio?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('verificarMovimientosPendientesPorAplicar', 'idNomina', idNomina)
            const localVarPath = `/movimiento-programado/verificar-pendientes-aplicar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (mes !== undefined) {
                localVarQueryParameter['mes'] = mes;
            }

            if (anio !== undefined) {
                localVarQueryParameter['anio'] = anio;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovimientoPagoApi - functional programming interface
 * @export
 */
export const MovimientoPagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovimientoPagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculoPeriodoUma>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAproximado(nivelSni, localizacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pago>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pago>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarExistePagosDemasiaInvestigador(cvu, fechas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MovimientoPagoApi - factory interface
 * @export
 */
export const MovimientoPagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovimientoPagoApiFp(configuration)
    return {
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: any): AxiosPromise<CalculoPeriodoUma> {
            return localVarFp.calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAproximado(nivelSni, localizacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: any): AxiosPromise<Pago> {
            return localVarFp.calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: any): AxiosPromise<Pago> {
            return localVarFp.calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: any): AxiosPromise<Array<MovimientoPago>> {
            return localVarFp.obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.validarExistePagosDemasiaInvestigador(cvu, fechas, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovimientoPagoApi - object-oriented interface
 * @export
 * @class MovimientoPagoApi
 * @extends {BaseAPI}
 */
export class MovimientoPagoApi extends BaseAPI {
    /**
     * Obtiene el cálculo del monto a aplicar con base a criterios
     * @param {string} idPago ID del pago
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {number} [monto] Monto en movimiento adicional
     * @param {number} [porcentaje] Porcentaje en movimiento adicional
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cálculo del monto a aplicar con base a las fechas
     * @param {string} idPago ID del pago
     * @param {string} [idNomina] ID de nomina
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto aproximado del investigador
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoAproximado(nivelSni, localizacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto de un movimiento pago a beneficiario
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [meses] Número de meses
     * @param {number} [porcentaje] Porcentaje en movimiento adicional
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto de un movimiento pago de incapacidad permanente
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [idMovimientoPago] ID del movimiento de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valida si existen pagos en demasía para un investigador fallecido
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).validarExistePagosDemasiaInvestigador(cvu, fechas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica si existen movimientos programados vigentes pendientes de aplicar
     * @param {string} idNomina ID de la nomina
     * @param {number} [mes] Mes de nómina
     * @param {number} [anio] Año de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoPagoApi
     */
    public verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: AxiosRequestConfig) {
        return MovimientoPagoApiFp(this.configuration).verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MovimientoProgramadoApi - axios parameter creator
 * @export
 */
export const MovimientoProgramadoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoProgramado: async (nuevoMovimientoPago: NuevoMovimientoPago, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoMovimientoPago' is not null or undefined
            assertParamExists('insertarMovimientoProgramado', 'nuevoMovimientoPago', nuevoMovimientoPago)
            const localVarPath = `/movimiento-programado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoMovimientoPago, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoMovimientoProgramado: async (idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('subirArchivoMovimientoProgramado', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivoMovimientoProgramado', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/movimiento-programado/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoArchivo !== undefined) {
                localVarQueryParameter['tipoArchivo'] = tipoArchivo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovimientoProgramadoApi - functional programming interface
 * @export
 */
export const MovimientoProgramadoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovimientoProgramadoApiAxiosParamCreator(configuration)
    return {
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertarMovimientoProgramado(nuevoMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MovimientoProgramadoApi - factory interface
 * @export
 */
export const MovimientoProgramadoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovimientoProgramadoApiFp(configuration)
    return {
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: any): AxiosPromise<string> {
            return localVarFp.insertarMovimientoProgramado(nuevoMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: any): AxiosPromise<string> {
            return localVarFp.subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovimientoProgramadoApi - object-oriented interface
 * @export
 * @class MovimientoProgramadoApi
 * @extends {BaseAPI}
 */
export class MovimientoProgramadoApi extends BaseAPI {
    /**
     * Insertar un movimiento programado
     * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoProgramadoApi
     */
    public insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig) {
        return MovimientoProgramadoApiFp(this.configuration).insertarMovimientoProgramado(nuevoMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
     * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientoProgramadoApi
     */
    public subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: AxiosRequestConfig) {
        return MovimientoProgramadoApiFp(this.configuration).subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MovimientosApi - axios parameter creator
 * @export
 */
export const MovimientosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados: async (nomina: Nomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nomina' is not null or undefined
            assertParamExists('aplicarMovimientosProgramados', 'nomina', nomina)
            const localVarPath = `/movimiento-programado/aplicar-movimientos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MovimientosApi - functional programming interface
 * @export
 */
export const MovimientosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MovimientosApiAxiosParamCreator(configuration)
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aplicarMovimientosProgramados(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MovimientosApi - factory interface
 * @export
 */
export const MovimientosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MovimientosApiFp(configuration)
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados(nomina: Nomina, options?: any): AxiosPromise<string> {
            return localVarFp.aplicarMovimientosProgramados(nomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MovimientosApi - object-oriented interface
 * @export
 * @class MovimientosApi
 * @extends {BaseAPI}
 */
export class MovimientosApi extends BaseAPI {
    /**
     * Aplica los movimientos programados a una prénomina
     * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MovimientosApi
     */
    public aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig) {
        return MovimientosApiFp(this.configuration).aplicarMovimientosProgramados(nomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NominaApi - axios parameter creator
 * @export
 */
export const NominaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Acepta la carga de pagos en el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aceptarSolicitudDispersionGestorPagos: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('aceptarSolicitudDispersionGestorPagos', 'idNomina', idNomina)
            const localVarPath = `/solicitud-dispersion/aceptar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarDocumentacionCompleta: async (idMovimientoPago: string, documentacionMovimiento?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('actualizarDocumentacionCompleta', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (documentacionMovimiento !== undefined) {
                localVarQueryParameter['documentacionMovimiento'] = documentacionMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza el estatus de movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} observacion Observación de un evento
         * @param {EstatusMovimientoPago} estatusMovimientoPago 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEstatusMovimientoProgramado: async (idMovimientoPago: string, observacion: string, estatusMovimientoPago: EstatusMovimientoPago, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('actualizarEstatusMovimientoProgramado', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'observacion' is not null or undefined
            assertParamExists('actualizarEstatusMovimientoProgramado', 'observacion', observacion)
            // verify required parameter 'estatusMovimientoPago' is not null or undefined
            assertParamExists('actualizarEstatusMovimientoProgramado', 'estatusMovimientoPago', estatusMovimientoPago)
            const localVarPath = `/movimiento-programado/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (observacion !== undefined) {
                localVarQueryParameter['observacion'] = observacion;
            }

            if (estatusMovimientoPago !== undefined) {
                localVarQueryParameter['estatusMovimientoPago'] = estatusMovimientoPago;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEventoNominaPago: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('actualizarEventoNominaPago', 'idNomina', idNomina)
            const localVarPath = `/evento/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica si se están generando pagos con JobRun y actualiza la propiedad generandoPagos
         * @param {string} idEncabezadoPadronNomina ID del encabezado padrón nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarGenerandoPagos: async (idEncabezadoPadronNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idEncabezadoPadronNomina' is not null or undefined
            assertParamExists('actualizarGenerandoPagos', 'idEncabezadoPadronNomina', idEncabezadoPadronNomina)
            const localVarPath = `/encabezado-padron-nomina/actualizar-generando-pagos/{idEncabezadoPadronNomina}`
                .replace(`{${"idEncabezadoPadronNomina"}}`, encodeURIComponent(String(idEncabezadoPadronNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una nómina
         * @param {Nomina} nomina Información de la nómina a actualizar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarNomina: async (nomina: Nomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nomina' is not null or undefined
            assertParamExists('actualizarNomina', 'nomina', nomina)
            const localVarPath = `/nomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados: async (nomina: Nomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nomina' is not null or undefined
            assertParamExists('aplicarMovimientosProgramados', 'nomina', nomina)
            const localVarPath = `/movimiento-programado/aplicar-movimientos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicar: async (idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('calcularMontoAplicar', 'idPago', idPago)
            const localVarPath = `/movimiento-pago/monto-aplicar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }

            if (monto !== undefined) {
                localVarQueryParameter['monto'] = monto;
            }

            if (porcentaje !== undefined) {
                localVarQueryParameter['porcentaje'] = porcentaje;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarFechas: async (idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('calcularMontoAplicarFechas', 'idPago', idPago)
            const localVarPath = `/movimiento-pago/monto-aplicar-fechas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarPeriodoUmas: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-pago/monto-aplicar-periodo-umas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (cantidadUmas !== undefined) {
                localVarQueryParameter['cantidadUmas'] = cantidadUmas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAproximado: async (nivelSni?: NivelSni, localizacion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-pago/calcular-aproximado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoBeneficiario: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/calcular-monto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (meses !== undefined) {
                localVarQueryParameter['meses'] = meses;
            }

            if (porcentaje !== undefined) {
                localVarQueryParameter['porcentaje'] = porcentaje;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoIncapacidadPermanente: async (fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/incapacidad-permanente/calcular-monto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (nivelSni !== undefined) {
                localVarQueryParameter['nivelSni'] = nivelSni;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conciliarNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('conciliarNomina', 'idNomina', idNomina)
            const localVarPath = `/conciliacion/conciliar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarArchivoConciliacion: async (archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarArchivoConciliacion', 'archivo', archivo)
            const localVarPath = `/conciliacion/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento: async (idMovimientoPago: string, archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('eliminarDocumento', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarDocumento', 'archivo', archivo)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumentoDms: async (archivo: Archivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'archivo' is not null or undefined
            assertParamExists('eliminarDocumentoDms', 'archivo', archivo)
            const localVarPath = `/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(archivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina el movimiento pago del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarMovimientoPagoAdicional: async (idMovimientoPago: string, idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('eliminarMovimientoPagoAdicional', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('eliminarMovimientoPagoAdicional', 'idNomina', idNomina)
            const localVarPath = `/movimiento-adicional/movimiento-pago/{idMovimientoPago}/{idNomina}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)))
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPago: async (idPago: string, observacion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('eliminarPago', 'idPago', idPago)
            // verify required parameter 'observacion' is not null or undefined
            assertParamExists('eliminarPago', 'observacion', observacion)
            const localVarPath = `/pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (observacion !== undefined) {
                localVarQueryParameter['observacion'] = observacion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina el pago movimiento pago del movimiento adicional
         * @param {string} idPagoMovimientoPago ID pago movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPagoMovimientoPagoAdicional: async (idPagoMovimientoPago: string, idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPagoMovimientoPago' is not null or undefined
            assertParamExists('eliminarPagoMovimientoPagoAdicional', 'idPagoMovimientoPago', idPagoMovimientoPago)
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('eliminarPagoMovimientoPagoAdicional', 'idNomina', idNomina)
            const localVarPath = `/movimiento-adicional/pago-movimiento-pago/{idPagoMovimientoPago}/{idNomina}`
                .replace(`{${"idPagoMovimientoPago"}}`, encodeURIComponent(String(idPagoMovimientoPago)))
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPrenomina: async (encabezadoPadronNomina: EncabezadoPadronNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'encabezadoPadronNomina' is not null or undefined
            assertParamExists('eliminarPrenomina', 'encabezadoPadronNomina', encabezadoPadronNomina)
            const localVarPath = `/pago/prenomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(encabezadoPadronNomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reintenta enviar al gestor de pagos los pagos faltantes de ser procesados por el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarPagosFaltantesGestorPagos: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('enviarPagosFaltantesGestorPagos', 'idNomina', idNomina)
            const localVarPath = `/solicitud-dispersion/enviar-pagos-faltantes/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envía los pagos a histórico de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarPagosHistoricoPagos: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('enviarPagosHistoricoPagos', 'idNomina', idNomina)
            const localVarPath = `/nomina/historico-pagos/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envía una solicitud de dispersión con las transacciones de pago al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudDispersionGestorPagos: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('enviarSolicitudDispersionGestorPagos', 'idNomina', idNomina)
            const localVarPath = `/solicitud-dispersion/enviar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Genera el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarArchivoDispersion: async (nomina?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nomina/archivo-dispersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (nomina !== undefined) {
                localVarQueryParameter['nomina'] = nomina;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarPrenomina: async (nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoEncabezadoPadronNomina' is not null or undefined
            assertParamExists('generarPrenomina', 'nuevoEncabezadoPadronNomina', nuevoEncabezadoPadronNomina)
            const localVarPath = `/pago/prenomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoEncabezadoPadronNomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Genera la solicitud de dispersión con sus pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarSolicitudDispersion: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('generarSolicitudDispersion', 'idNomina', idNomina)
            const localVarPath = `/solicitud-dispersion/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado con nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNominaSample: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nomina-sample`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoAdicional: async (nuevoMovimientoPago: NuevoMovimientoPago, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoMovimientoPago' is not null or undefined
            assertParamExists('insertarMovimientoAdicional', 'nuevoMovimientoPago', nuevoMovimientoPago)
            const localVarPath = `/movimiento-adicional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoMovimientoPago, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoProgramado: async (nuevoMovimientoPago: NuevoMovimientoPago, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoMovimientoPago' is not null or undefined
            assertParamExists('insertarMovimientoProgramado', 'nuevoMovimientoPago', nuevoMovimientoPago)
            const localVarPath = `/movimiento-programado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoMovimientoPago, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Insertar una nómina
         * @param {NuevaNomina} nuevaNomina Información de la nómina a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarNomina: async (nuevaNomina: NuevaNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevaNomina' is not null or undefined
            assertParamExists('insertarNomina', 'nuevaNomina', nuevaNomina)
            const localVarPath = `/nomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevaNomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de los años de las nóminas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAniosNomina: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nomina/anios-nomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivoDispersion: async (nomina?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nomina/archivo-dispersion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (nomina !== undefined) {
                localVarQueryParameter['nomina'] = nomina;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivos: async (idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/archivo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (tipoArchivo) {
                localVarQueryParameter['tipoArchivo'] = tipoArchivo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAsignacionPersonaReceptorCvuInvestigador: async (cvu?: number, tipoReceptor?: TipoReceptor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/asignacion-persona/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la lista de los estatus del movimiento programado
         * @param {string} idMovimiento ID del movimiento programado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerBitacoraMovimientoPago: async (idMovimiento: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimiento' is not null or undefined
            assertParamExists('obtenerBitacoraMovimientoPago', 'idMovimiento', idMovimiento)
            const localVarPath = `/movimiento-programado/obtener-bitacora`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idMovimiento !== undefined) {
                localVarQueryParameter['idMovimiento'] = idMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerCategoriasPorTipoMovimientoPago: async (idTipoMovimiento: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idTipoMovimiento' is not null or undefined
            assertParamExists('obtenerCategoriasPorTipoMovimientoPago', 'idTipoMovimiento', idTipoMovimiento)
            const localVarPath = `/categoria-movimiento-pago/{idTipoMovimiento}`
                .replace(`{${"idTipoMovimiento"}}`, encodeURIComponent(String(idTipoMovimiento)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de las claves de nómina inicial en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerClavesNominaInicial: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerClavesNominaInicial', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/claves-nominas-menu`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de las claves de las nóminas que existen en el movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerClavesNominasHistorial: async (idMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerClavesNominasHistorial', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/pago-movimiento-pago/{idMovimientoPago}/claves-nominas-menu`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetalleMovimientoAdicional: async (idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerDetalleMovimientoAdicional', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetallePorCategoriaMovimientoPago: async (idCategoriaMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idCategoriaMovimientoPago' is not null or undefined
            assertParamExists('obtenerDetallePorCategoriaMovimientoPago', 'idCategoriaMovimientoPago', idCategoriaMovimientoPago)
            const localVarPath = `/detalle-movimiento-pago/{idCategoriaMovimientoPago}`
                .replace(`{${"idCategoriaMovimientoPago"}}`, encodeURIComponent(String(idCategoriaMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEncabezadoPadronNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerEncabezadoPadronNomina', 'idNomina', idNomina)
            const localVarPath = `/encabezado-padron-nomina/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de las entidades federativas que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEntidadesNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerEntidadesNomina', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/entidades-menu`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstadoJob: async (jobId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('obtenerEstadoJob', 'jobId', jobId)
            const localVarPath = `/job/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de un movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientoProgramado: async (idMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerMovimientoProgramado', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-programado/{idMovimientoPago}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosActivosPorCategoriaDetalle: async (cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/activos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }

            if (claveDetalleMovimiento !== undefined) {
                localVarQueryParameter['claveDetalleMovimiento'] = claveDetalleMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosAdicionalesFiltrados: async (idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (tipoMovimiento !== undefined) {
                localVarQueryParameter['tipoMovimiento'] = tipoMovimiento;
            }

            if (categoriaMovimientoPago !== undefined) {
                localVarQueryParameter['categoriaMovimientoPago'] = categoriaMovimientoPago;
            }

            if (detalleMovimientoPago !== undefined) {
                localVarQueryParameter['detalleMovimientoPago'] = detalleMovimientoPago;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (frecuenciaMovimientoPago !== undefined) {
                localVarQueryParameter['frecuenciaMovimientoPago'] = frecuenciaMovimientoPago;
            }

            if (documentacionMovimiento !== undefined) {
                localVarQueryParameter['documentacionMovimiento'] = documentacionMovimiento;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosProgramados: async (cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-programado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (claveTipoMovimiento !== undefined) {
                localVarQueryParameter['claveTipoMovimiento'] = claveTipoMovimiento;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }

            if (estatusMovimiento !== undefined) {
                localVarQueryParameter['estatusMovimiento'] = estatusMovimiento;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosVigentesPorCategoria: async (cvu?: number, claveCategoriaMovimiento?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-programado/vigentes-categoria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerNomina', 'idNomina', idNomina)
            const localVarPath = `/nomina/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de las nóminas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {string} [clave] Clave de la nómina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerNominas: async (anio?: number, mes?: number, clave?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (anio !== undefined) {
                localVarQueryParameter['anio'] = anio;
            }

            if (mes !== undefined) {
                localVarQueryParameter['mes'] = mes;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPaginaDeArchivos: async (idMovimientoPago: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerPaginaDeArchivos', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosBeneficiariosPorInvestigador: async (cvu?: number, idMovimientoPago?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/buscar-beneficiarios`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (idMovimientoPago !== undefined) {
                localVarQueryParameter['idMovimientoPago'] = idMovimientoPago;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos utilizando un ID de nómina y una lista de CVUs.
         * @summary Obtiene lista de pagos por IdNomina y lista de CVUs.
         * @param {string} idNomina ID de la nomina
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosByIdNominaAndCvus: async (idNomina: string, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosByIdNominaAndCvus', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/obtiene-pagos-solicitados`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosConciliados: async (idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosConciliados', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/pagos-conciliados`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosExcluyendoEstatus: async (idNomina: string, estatusPagoArray: Array<EstatusPago>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosExcluyendoEstatus', 'idNomina', idNomina)
            // verify required parameter 'estatusPagoArray' is not null or undefined
            assertParamExists('obtenerPagosExcluyendoEstatus', 'estatusPagoArray', estatusPagoArray)
            const localVarPath = `/pago/obtener-excluyendo-estatus/{idNomina}/{estatusPagoArray}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (estatusPagoArray) {
                localVarQueryParameter['estatusPagoArray[]'] = estatusPagoArray;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos del movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [clave] Clave de la nómina
         * @param {string} [fecha] Fecha
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPago: async (idMovimientoPago: string, clave?: string, fecha?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerPagosMovimientoPago', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-programado/{idMovimientoPago}/historial`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (fecha !== undefined) {
                localVarQueryParameter['fecha'] = (fecha as any instanceof Date) ?
                    (fecha as any).toISOString().substr(0,10) :
                    fecha;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPagoPorEstatusNomina: async (idMovimientoPago: string, estatusNomina: EstatusNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerPagosMovimientoPagoPorEstatusNomina', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'estatusNomina' is not null or undefined
            assertParamExists('obtenerPagosMovimientoPagoPorEstatusNomina', 'estatusNomina', estatusNomina)
            const localVarPath = `/pago-movimiento-pago/{idMovimientoPago}/{estatusNomina}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)))
                .replace(`{${"estatusNomina"}}`, encodeURIComponent(String(estatusNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientosActivosPorPago: async (idPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('obtenerPagosMovimientosActivosPorPago', 'idPago', idPago)
            const localVarPath = `/pago-movimiento-pago/{idPago}`
                .replace(`{${"idPago"}}`, encodeURIComponent(String(idPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosNomina: async (idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (tipoReceptorNivel !== undefined) {
                localVarQueryParameter['tipoReceptorNivel'] = tipoReceptorNivel;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosPorReportado: async (idNomina: string, reportado: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosPorReportado', 'idNomina', idNomina)
            // verify required parameter 'reportado' is not null or undefined
            assertParamExists('obtenerPagosPorReportado', 'reportado', reportado)
            const localVarPath = `/pago/reportado/{idNomina}/{reportado}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"reportado"}}`, encodeURIComponent(String(reportado)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosTipoReceptor: async (idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosTipoReceptor', 'idNomina', idNomina)
            // verify required parameter 'conMontoCero' is not null or undefined
            assertParamExists('obtenerPagosTipoReceptor', 'conMontoCero', conMontoCero)
            const localVarPath = `/pago/tipo-receptor/{idNomina}/{tipoReceptor}/{conMontoCero}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"conMontoCero"}}`, encodeURIComponent(String(conMontoCero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de los tipos de receptores y nivel sni que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReceptoresNomina: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerReceptoresNomina', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/receptor-menu`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReporteRegional: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerReporteRegional', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/reporte-regional`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagos: async (idNomina: string, conMontoCero: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerResumenPagos', 'idNomina', idNomina)
            // verify required parameter 'conMontoCero' is not null or undefined
            assertParamExists('obtenerResumenPagos', 'conMontoCero', conMontoCero)
            const localVarPath = `/pago/{idNomina}/resumen-pagos/{conMontoCero}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"conMontoCero"}}`, encodeURIComponent(String(conMontoCero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagosConciliados: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerResumenPagosConciliados', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/resumen-pagos-conciliados`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los datos generales de la solicitud de dispersión al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerSolicitudDispersion: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerSolicitudDispersion', 'idNomina', idNomina)
            const localVarPath = `/solicitud-dispersion/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTiposMovimientoPago: async (clave?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tipo-movimiento-pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (clave) {
                localVarQueryParameter['clave'] = clave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalMovimientosDocumentados: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerTotalMovimientosDocumentados', 'idNomina', idNomina)
            const localVarPath = `/movimiento-adicional/total-documentados/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalPagosPorReportado: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerTotalPagosPorReportado', 'idNomina', idNomina)
            const localVarPath = `/pago/reportados-total/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerValidacionPuedeActualizarArchivos: async (idMovimientoPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerValidacionPuedeActualizarArchivos', 'idMovimientoPago', idMovimientoPago)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/puede-actualizar-archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoConciliacion: async (idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('subirArchivoConciliacion', 'idNomina', idNomina)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivoConciliacion', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/conciliacion/subir-archivo/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoMovimientoProgramado: async (idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('subirArchivoMovimientoProgramado', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivoMovimientoProgramado', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/movimiento-programado/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoArchivo !== undefined) {
                localVarQueryParameter['tipoArchivo'] = tipoArchivo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivos: async (idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('subirArchivos', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'informacionArchivo' is not null or undefined
            assertParamExists('subirArchivos', 'informacionArchivo', informacionArchivo)
            const localVarPath = `/movimiento-adicional/{idMovimientoPago}/archivo`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(informacionArchivo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarAnioUma: async (anio?: number, mes?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valorUma`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (anio !== undefined) {
                localVarQueryParameter['anio'] = anio;
            }

            if (mes !== undefined) {
                localVarQueryParameter['mes'] = mes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valida si se puede editar el movimiento adicional
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarEdicionMovimientoAdicional: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('validarEdicionMovimientoAdicional', 'idNomina', idNomina)
            const localVarPath = `/movimiento-adicional/valida-edicion/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarExistePagosDemasiaInvestigador: async (cvu?: number, fechas?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-adicional/beneficiario/pagos-demasia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificarMovimientosPendientesPorAplicar: async (idNomina: string, mes?: number, anio?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('verificarMovimientosPendientesPorAplicar', 'idNomina', idNomina)
            const localVarPath = `/movimiento-programado/verificar-pendientes-aplicar/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (mes !== undefined) {
                localVarQueryParameter['mes'] = mes;
            }

            if (anio !== undefined) {
                localVarQueryParameter['anio'] = anio;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NominaApi - functional programming interface
 * @export
 */
export const NominaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NominaApiAxiosParamCreator(configuration)
    return {
        /**
         * Acepta la carga de pagos en el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aceptarSolicitudDispersionGestorPagos(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aceptarSolicitudDispersionGestorPagos(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza el estatus de movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} observacion Observación de un evento
         * @param {EstatusMovimientoPago} estatusMovimientoPago 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarEstatusMovimientoProgramado(idMovimientoPago: string, observacion: string, estatusMovimientoPago: EstatusMovimientoPago, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarEstatusMovimientoProgramado(idMovimientoPago, observacion, estatusMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarEventoNominaPago(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarEventoNominaPago(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica si se están generando pagos con JobRun y actualiza la propiedad generandoPagos
         * @param {string} idEncabezadoPadronNomina ID del encabezado padrón nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarGenerandoPagos(idEncabezadoPadronNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarGenerandoPagos(idEncabezadoPadronNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una nómina
         * @param {Nomina} nomina Información de la nómina a actualizar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actualizarNomina(nomina: Nomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actualizarNomina(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aplicarMovimientosProgramados(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculoPeriodoUma>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoAproximado(nivelSni, localizacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pago>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pago>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conciliarNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conciliarNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarArchivoConciliacion(archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarArchivoConciliacion(archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumento(idMovimientoPago, archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumentoDms(archivo: Archivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumentoDms(archivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina el movimiento pago del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarMovimientoPagoAdicional(idMovimientoPago: string, idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarMovimientoPagoAdicional(idMovimientoPago, idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarPago(idPago: string, observacion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarPago(idPago, observacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina el pago movimiento pago del movimiento adicional
         * @param {string} idPagoMovimientoPago ID pago movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago: string, idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago, idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarPrenomina(encabezadoPadronNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reintenta enviar al gestor de pagos los pagos faltantes de ser procesados por el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarPagosFaltantesGestorPagos(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarPagosFaltantesGestorPagos(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Envía los pagos a histórico de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarPagosHistoricoPagos(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarPagosHistoricoPagos(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Envía una solicitud de dispersión con las transacciones de pago al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitudDispersionGestorPagos(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitudDispersionGestorPagos(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Genera el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generarArchivoDispersion(nomina?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generarArchivoDispersion(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generarPrenomina(nuevoEncabezadoPadronNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Genera la solicitud de dispersión con sus pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generarSolicitudDispersion(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generarSolicitudDispersion(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado con nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllNominaSample(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NominaSample>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllNominaSample(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertarMovimientoAdicional(nuevoMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertarMovimientoProgramado(nuevoMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Insertar una nómina
         * @param {NuevaNomina} nuevaNomina Información de la nómina a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async insertarNomina(nuevaNomina: NuevaNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.insertarNomina(nuevaNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de los años de las nóminas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerAniosNomina(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpcionMenu>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerAniosNomina(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerArchivoDispersion(nomina?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformacionArchivo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerArchivoDispersion(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Archivo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerArchivos(idNomina, tipoArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AsignacionPersona>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la lista de los estatus del movimiento programado
         * @param {string} idMovimiento ID del movimiento programado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerBitacoraMovimientoPago(idMovimiento: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerBitacoraMovimientoPago(idMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoriaMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de las claves de nómina inicial en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerClavesNominaInicial(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpcionMenuFiltro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerClavesNominaInicial(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de las claves de las nóminas que existen en el movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerClavesNominasHistorial(idMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpcionMenuFiltro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerClavesNominasHistorial(idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DetalleMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEncabezadoPadronNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EncabezadoPadronNomina>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEncabezadoPadronNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de las entidades federativas que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEntidadesNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpcionMenuFiltro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEntidadesNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerEstadoJob(jobId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerEstadoJob(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de un movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientoProgramado(idMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MovimientoPago>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientoProgramado(idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InformacionNomina>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de las nóminas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {string} [clave] Clave de la nómina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerNominas(anio?: number, mes?: number, clave?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InformacionNomina>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerNominas(anio, mes, clave, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Archivo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPaginaDeArchivos(idMovimientoPago, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos utilizando un ID de nómina y una lista de CVUs.
         * @summary Obtiene lista de pagos por IdNomina y lista de CVUs.
         * @param {string} idNomina ID de la nomina
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosByIdNominaAndCvus(idNomina: string, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosByIdNominaAndCvus(idNomina, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagosConciliados>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos del movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [clave] Clave de la nómina
         * @param {string} [fecha] Fecha
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosMovimientoPago(idMovimientoPago: string, clave?: string, fecha?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistorialPagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosMovimientoPago(idMovimientoPago, clave, fecha, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosMovimientosActivosPorPago(idPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosMovimientosActivosPorPago(idPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosPorReportado(idNomina, reportado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de los tipos de receptores y nivel sni que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerReceptoresNomina(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpcionMenuFiltro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerReceptoresNomina(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerReporteRegional(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReporteRegional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerReporteRegional(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResumenPagos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerResumenPagos(idNomina, conMontoCero, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerResumenPagosConciliados(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResumenPagosConciliados>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerResumenPagosConciliados(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los datos generales de la solicitud de dispersión al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerSolicitudDispersion(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolicitudDispersion>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerSolicitudDispersion(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTiposMovimientoPago(clave?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TipoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTiposMovimientoPago(clave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTotalMovimientosDocumentados(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalMovimientosDocumentados>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTotalMovimientosDocumentados(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTotalPagosPorReportado(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagosReportadosTotal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTotalPagosPorReportado(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivoConciliacion(idNomina, informacionArchivo, clave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subirArchivos(idMovimientoPago, informacionArchivo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarAnioUma(anio?: number, mes?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarAnioUma(anio, mes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valida si se puede editar el movimiento adicional
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarEdicionMovimientoAdicional(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarEdicionMovimientoAdicional(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarExistePagosDemasiaInvestigador(cvu, fechas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NominaApi - factory interface
 * @export
 */
export const NominaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NominaApiFp(configuration)
    return {
        /**
         * Acepta la carga de pagos en el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aceptarSolicitudDispersionGestorPagos(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.aceptarSolicitudDispersionGestorPagos(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza documentación completa del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza el estatus de movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} observacion Observación de un evento
         * @param {EstatusMovimientoPago} estatusMovimientoPago 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEstatusMovimientoProgramado(idMovimientoPago: string, observacion: string, estatusMovimientoPago: EstatusMovimientoPago, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarEstatusMovimientoProgramado(idMovimientoPago, observacion, estatusMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarEventoNominaPago(idNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarEventoNominaPago(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica si se están generando pagos con JobRun y actualiza la propiedad generandoPagos
         * @param {string} idEncabezadoPadronNomina ID del encabezado padrón nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarGenerandoPagos(idEncabezadoPadronNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarGenerandoPagos(idEncabezadoPadronNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una nómina
         * @param {Nomina} nomina Información de la nómina a actualizar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actualizarNomina(nomina: Nomina, options?: any): AxiosPromise<void> {
            return localVarFp.actualizarNomina(nomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados(nomina: Nomina, options?: any): AxiosPromise<string> {
            return localVarFp.aplicarMovimientosProgramados(nomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a criterios
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {number} [monto] Monto en movimiento adicional
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cálculo del monto a aplicar con base a las fechas
         * @param {string} idPago ID del pago
         * @param {string} [idNomina] ID de nomina
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: any): AxiosPromise<CalculoPeriodoUma> {
            return localVarFp.calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto aproximado del investigador
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: any): AxiosPromise<number> {
            return localVarFp.calcularMontoAproximado(nivelSni, localizacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto de un movimiento pago a beneficiario
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [meses] Número de meses
         * @param {number} [porcentaje] Porcentaje en movimiento adicional
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: any): AxiosPromise<Pago> {
            return localVarFp.calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Calcula el monto de un movimiento pago de incapacidad permanente
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {NivelSni} [nivelSni] Nivel Sni del investigador
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: any): AxiosPromise<Pago> {
            return localVarFp.calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Procesa la conciliación de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conciliarNomina(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.conciliarNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un archivo de conciliación y actualiza los pagos reportados a false
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarArchivoConciliacion(archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarArchivoConciliacion(archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumento(idMovimientoPago, archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un archivo del Dms y su referencia en base de datos
         * @param {Archivo} archivo Archivo a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumentoDms(archivo: Archivo, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumentoDms(archivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina el movimiento pago del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarMovimientoPagoAdicional(idMovimientoPago: string, idNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarMovimientoPagoAdicional(idMovimientoPago, idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPago(idPago: string, observacion: string, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarPago(idPago, observacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina el pago movimiento pago del movimiento adicional
         * @param {string} idPagoMovimientoPago ID pago movimiento pago
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago: string, idNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago, idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarPrenomina(encabezadoPadronNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Reintenta enviar al gestor de pagos los pagos faltantes de ser procesados por el gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarPagosFaltantesGestorPagos(idNomina: string, options?: any): AxiosPromise<void> {
            return localVarFp.enviarPagosFaltantesGestorPagos(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Envía los pagos a histórico de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarPagosHistoricoPagos(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.enviarPagosHistoricoPagos(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Envía una solicitud de dispersión con las transacciones de pago al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudDispersionGestorPagos(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.enviarSolicitudDispersionGestorPagos(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Genera el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarArchivoDispersion(nomina?: string, options?: any): AxiosPromise<void> {
            return localVarFp.generarArchivoDispersion(nomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: any): AxiosPromise<void> {
            return localVarFp.generarPrenomina(nuevoEncabezadoPadronNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Genera la solicitud de dispersión con sus pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarSolicitudDispersion(idNomina: string, options?: any): AxiosPromise<string> {
            return localVarFp.generarSolicitudDispersion(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado con nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNominaSample(page?: number, size?: number, options?: any): AxiosPromise<Array<NominaSample>> {
            return localVarFp.getAllNominaSample(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Inserta un movimiento adicional
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: any): AxiosPromise<string> {
            return localVarFp.insertarMovimientoAdicional(nuevoMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Insertar un movimiento programado
         * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: any): AxiosPromise<string> {
            return localVarFp.insertarMovimientoProgramado(nuevoMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Insertar una nómina
         * @param {NuevaNomina} nuevaNomina Información de la nómina a insertar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        insertarNomina(nuevaNomina: NuevaNomina, options?: any): AxiosPromise<string> {
            return localVarFp.insertarNomina(nuevaNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de los años de las nóminas
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAniosNomina(options?: any): AxiosPromise<Array<OpcionMenu>> {
            return localVarFp.obtenerAniosNomina(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el archivo de dispersión
         * @param {string} [nomina] Identificador de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivoDispersion(nomina?: string, options?: any): AxiosPromise<InformacionArchivo> {
            return localVarFp.obtenerArchivoDispersion(nomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de los archivos por tipo asociados a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: any): AxiosPromise<Array<Archivo>> {
            return localVarFp.obtenerArchivos(idNomina, tipoArchivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: any): AxiosPromise<Array<AsignacionPersona>> {
            return localVarFp.obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la lista de los estatus del movimiento programado
         * @param {string} idMovimiento ID del movimiento programado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerBitacoraMovimientoPago(idMovimiento: string, options?: any): AxiosPromise<Array<EventoMovimientoPago>> {
            return localVarFp.obtenerBitacoraMovimientoPago(idMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado las categorías por tipo de movimiento de pago
         * @param {string} idTipoMovimiento ID tipo de movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: any): AxiosPromise<Array<CategoriaMovimientoPago>> {
            return localVarFp.obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de las claves de nómina inicial en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerClavesNominaInicial(idNomina: string, options?: any): AxiosPromise<Array<OpcionMenuFiltro>> {
            return localVarFp.obtenerClavesNominaInicial(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de las claves de las nóminas que existen en el movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerClavesNominasHistorial(idMovimientoPago: string, options?: any): AxiosPromise<Array<OpcionMenuFiltro>> {
            return localVarFp.obtenerClavesNominasHistorial(idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el detalle del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [idNomina] ID de nomina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
         * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: any): AxiosPromise<Array<DetalleMovimientoPago>> {
            return localVarFp.obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el encabezado del padrón de nómina asociado a la nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEncabezadoPadronNomina(idNomina: string, options?: any): AxiosPromise<EncabezadoPadronNomina> {
            return localVarFp.obtenerEncabezadoPadronNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de las entidades federativas que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEntidadesNomina(idNomina: string, options?: any): AxiosPromise<Array<OpcionMenuFiltro>> {
            return localVarFp.obtenerEntidadesNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el estado de un job
         * @param {string} jobId ID del job
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerEstadoJob(jobId: string, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerEstadoJob(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de un movimiento pago programado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientoProgramado(idMovimientoPago: string, options?: any): AxiosPromise<MovimientoPago> {
            return localVarFp.obtenerMovimientoProgramado(idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos adicionales filtrados
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoMovimiento] Tipo de movimiento
         * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
         * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
         * @param {string} [clave] Clave del movimiento
         * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
         * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<Array<MovimientoPago>> {
            return localVarFp.obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: any): AxiosPromise<Array<MovimientoPago>> {
            return localVarFp.obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerNomina(idNomina: string, options?: any): AxiosPromise<InformacionNomina> {
            return localVarFp.obtenerNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de las nóminas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {string} [clave] Clave de la nómina
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerNominas(anio?: number, mes?: number, clave?: string, page?: number, size?: number, options?: any): AxiosPromise<Array<InformacionNomina>> {
            return localVarFp.obtenerNominas(anio, mes, clave, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * consulta una página de los documentos del movimiento adicional
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: any): AxiosPromise<Array<Archivo>> {
            return localVarFp.obtenerPaginaDeArchivos(idMovimientoPago, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [idMovimientoPago] ID del movimiento de pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos utilizando un ID de nómina y una lista de CVUs.
         * @summary Obtiene lista de pagos por IdNomina y lista de CVUs.
         * @param {string} idNomina ID de la nomina
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosByIdNominaAndCvus(idNomina: string, requestBody?: Array<number>, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosByIdNominaAndCvus(idNomina, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: any): AxiosPromise<Array<PagosConciliados>> {
            return localVarFp.obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: any): AxiosPromise<number> {
            return localVarFp.obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos del movimiento de pago
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {string} [clave] Clave de la nómina
         * @param {string} [fecha] Fecha
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPago(idMovimientoPago: string, clave?: string, fecha?: string, page?: number, size?: number, options?: any): AxiosPromise<Array<HistorialPagoMovimientoPago>> {
            return localVarFp.obtenerPagosMovimientoPago(idMovimientoPago, clave, fecha, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientosActivosPorPago(idPago: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosMovimientosActivosPorPago(idPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosPorReportado(idNomina, reportado, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de los tipos de receptores y nivel sni que existen en los pagos de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReceptoresNomina(idNomina: string, options?: any): AxiosPromise<Array<OpcionMenuFiltro>> {
            return localVarFp.obtenerReceptoresNomina(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReporteRegional(idNomina: string, options?: any): AxiosPromise<Array<ReporteRegional>> {
            return localVarFp.obtenerReporteRegional(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: any): AxiosPromise<Array<ResumenPagos>> {
            return localVarFp.obtenerResumenPagos(idNomina, conMontoCero, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagosConciliados(idNomina: string, options?: any): AxiosPromise<Array<ResumenPagosConciliados>> {
            return localVarFp.obtenerResumenPagosConciliados(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los datos generales de la solicitud de dispersión al gestor de pagos
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerSolicitudDispersion(idNomina: string, options?: any): AxiosPromise<SolicitudDispersion> {
            return localVarFp.obtenerSolicitudDispersion(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTiposMovimientoPago(clave?: Array<string>, options?: any): AxiosPromise<Array<TipoMovimientoPago>> {
            return localVarFp.obtenerTiposMovimientoPago(clave, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalMovimientosDocumentados(idNomina: string, options?: any): AxiosPromise<TotalMovimientosDocumentados> {
            return localVarFp.obtenerTotalMovimientosDocumentados(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalPagosPorReportado(idNomina: string, options?: any): AxiosPromise<PagosReportadosTotal> {
            return localVarFp.obtenerTotalPagosPorReportado(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Consulta una validación booleana para saber si se permite realizar actualización de documentos
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options).then((request) => request(axios, basePath));
        },
        /**
         * Valida el archivo de conciliación para subirlo al repositorio DMS
         * @param {string} idNomina ID de la nomina
         * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
         * @param {string} [clave] Clave de la nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: any): AxiosPromise<string> {
            return localVarFp.subirArchivoConciliacion(idNomina, informacionArchivo, clave, options).then((request) => request(axios, basePath));
        },
        /**
         * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
         * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: any): AxiosPromise<string> {
            return localVarFp.subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Sube documentos al DMS y guarda la referencia en archivo
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: any): AxiosPromise<void> {
            return localVarFp.subirArchivos(idMovimientoPago, informacionArchivo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarAnioUma(anio?: number, mes?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.validarAnioUma(anio, mes, options).then((request) => request(axios, basePath));
        },
        /**
         * Valida si se puede editar el movimiento adicional
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarEdicionMovimientoAdicional(idNomina: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.validarEdicionMovimientoAdicional(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Valida si existen pagos en demasía para un investigador fallecido
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: any): AxiosPromise<boolean> {
            return localVarFp.validarExistePagosDemasiaInvestigador(cvu, fechas, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica si existen movimientos programados vigentes pendientes de aplicar
         * @param {string} idNomina ID de la nomina
         * @param {number} [mes] Mes de nómina
         * @param {number} [anio] Año de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NominaApi - object-oriented interface
 * @export
 * @class NominaApi
 * @extends {BaseAPI}
 */
export class NominaApi extends BaseAPI {
    /**
     * Acepta la carga de pagos en el gestor de pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public aceptarSolicitudDispersionGestorPagos(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).aceptarSolicitudDispersionGestorPagos(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza documentación completa del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public actualizarDocumentacionCompleta(idMovimientoPago: string, documentacionMovimiento?: boolean, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).actualizarDocumentacionCompleta(idMovimientoPago, documentacionMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza el estatus de movimiento pago programado
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {string} observacion Observación de un evento
     * @param {EstatusMovimientoPago} estatusMovimientoPago 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public actualizarEstatusMovimientoProgramado(idMovimientoPago: string, observacion: string, estatusMovimientoPago: EstatusMovimientoPago, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).actualizarEstatusMovimientoProgramado(idMovimientoPago, observacion, estatusMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza los eventos y estatus de una nómina y/o de los pagos de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public actualizarEventoNominaPago(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).actualizarEventoNominaPago(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica si se están generando pagos con JobRun y actualiza la propiedad generandoPagos
     * @param {string} idEncabezadoPadronNomina ID del encabezado padrón nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public actualizarGenerandoPagos(idEncabezadoPadronNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).actualizarGenerandoPagos(idEncabezadoPadronNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una nómina
     * @param {Nomina} nomina Información de la nómina a actualizar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public actualizarNomina(nomina: Nomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).actualizarNomina(nomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Aplica los movimientos programados a una prénomina
     * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).aplicarMovimientosProgramados(nomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cálculo del monto a aplicar con base a criterios
     * @param {string} idPago ID del pago
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {number} [monto] Monto en movimiento adicional
     * @param {number} [porcentaje] Porcentaje en movimiento adicional
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoAplicar(idPago: string, idNomina?: string, cantidadUmas?: number, monto?: number, porcentaje?: number, categoriaMovimientoPago?: string, fechas?: Array<string>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoAplicar(idPago, idNomina, cantidadUmas, monto, porcentaje, categoriaMovimientoPago, fechas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cálculo del monto a aplicar con base a las fechas
     * @param {string} idPago ID del pago
     * @param {string} [idNomina] ID de nomina
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoAplicarFechas(idPago: string, idNomina?: string, fechas?: Array<string>, nivelSni?: NivelSni, cantidadUmas?: number, localizacion?: string, categoriaMovimientoPago?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoAplicarFechas(idPago, idNomina, fechas, nivelSni, cantidadUmas, localizacion, categoriaMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cálculo del monto a aplicar y el periodo de Umas con base a las fechas
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [cantidadUmas] Cantidad de umas en movimiento adicional
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoAplicarPeriodoUmas(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cantidadUmas?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoAplicarPeriodoUmas(fechas, nivelSni, localizacion, cantidadUmas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto aproximado del investigador
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoAproximado(nivelSni?: NivelSni, localizacion?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoAproximado(nivelSni, localizacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto de un movimiento pago a beneficiario
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [meses] Número de meses
     * @param {number} [porcentaje] Porcentaje en movimiento adicional
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoMovimientoPagoBeneficiario(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, meses?: number, porcentaje?: number, cvu?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoMovimientoPagoBeneficiario(fechas, nivelSni, localizacion, meses, porcentaje, cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calcula el monto de un movimiento pago de incapacidad permanente
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {NivelSni} [nivelSni] Nivel Sni del investigador
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public calcularMontoMovimientoPagoIncapacidadPermanente(fechas?: Array<string>, nivelSni?: NivelSni, localizacion?: string, cvu?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).calcularMontoMovimientoPagoIncapacidadPermanente(fechas, nivelSni, localizacion, cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Procesa la conciliación de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public conciliarNomina(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).conciliarNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un archivo de conciliación y actualiza los pagos reportados a false
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarArchivoConciliacion(archivo: Archivo, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarArchivoConciliacion(archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un archivo del Dms y su referencia en base de datos
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarDocumento(idMovimientoPago: string, archivo: Archivo, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarDocumento(idMovimientoPago, archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un archivo del Dms y su referencia en base de datos
     * @param {Archivo} archivo Archivo a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarDocumentoDms(archivo: Archivo, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarDocumentoDms(archivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina el movimiento pago del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarMovimientoPagoAdicional(idMovimientoPago: string, idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarMovimientoPagoAdicional(idMovimientoPago, idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina lógica el pago
     * @param {string} idPago ID del pago
     * @param {string} observacion Observación de un evento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarPago(idPago: string, observacion: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarPago(idPago, observacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina el pago movimiento pago del movimiento adicional
     * @param {string} idPagoMovimientoPago ID pago movimiento pago
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago: string, idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarPagoMovimientoPagoAdicional(idPagoMovimientoPago, idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una prenómina
     * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).eliminarPrenomina(encabezadoPadronNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reintenta enviar al gestor de pagos los pagos faltantes de ser procesados por el gestor de pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public enviarPagosFaltantesGestorPagos(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).enviarPagosFaltantesGestorPagos(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envía los pagos a histórico de pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public enviarPagosHistoricoPagos(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).enviarPagosHistoricoPagos(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envía una solicitud de dispersión con las transacciones de pago al gestor de pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public enviarSolicitudDispersionGestorPagos(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).enviarSolicitudDispersionGestorPagos(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Genera el archivo de dispersión
     * @param {string} [nomina] Identificador de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public generarArchivoDispersion(nomina?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).generarArchivoDispersion(nomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
     * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).generarPrenomina(nuevoEncabezadoPadronNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Genera la solicitud de dispersión con sus pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public generarSolicitudDispersion(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).generarSolicitudDispersion(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado con nomina
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public getAllNominaSample(page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).getAllNominaSample(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Inserta un movimiento adicional
     * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento adicional a insertar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public insertarMovimientoAdicional(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).insertarMovimientoAdicional(nuevoMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insertar un movimiento programado
     * @param {NuevoMovimientoPago} nuevoMovimientoPago Información del movimiento programado a insertar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public insertarMovimientoProgramado(nuevoMovimientoPago: NuevoMovimientoPago, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).insertarMovimientoProgramado(nuevoMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Insertar una nómina
     * @param {NuevaNomina} nuevaNomina Información de la nómina a insertar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public insertarNomina(nuevaNomina: NuevaNomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).insertarNomina(nuevaNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de los años de las nóminas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerAniosNomina(options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerAniosNomina(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el archivo de dispersión
     * @param {string} [nomina] Identificador de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerArchivoDispersion(nomina?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerArchivoDispersion(nomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de los archivos por tipo asociados a una nómina
     * @param {string} [idNomina] ID de nomina
     * @param {Array<TipoArchivo>} [tipoArchivo] Tipo de archivo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerArchivos(idNomina?: string, tipoArchivo?: Array<TipoArchivo>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerArchivos(idNomina, tipoArchivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene las personas por tipo de receptor del movimiento de pago asignadas al investigador
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerAsignacionPersonaReceptorCvuInvestigador(cvu?: number, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerAsignacionPersonaReceptorCvuInvestigador(cvu, tipoReceptor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la lista de los estatus del movimiento programado
     * @param {string} idMovimiento ID del movimiento programado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerBitacoraMovimientoPago(idMovimiento: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerBitacoraMovimientoPago(idMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado las categorías por tipo de movimiento de pago
     * @param {string} idTipoMovimiento ID tipo de movimiento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerCategoriasPorTipoMovimientoPago(idTipoMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de las claves de nómina inicial en los pagos de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerClavesNominaInicial(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerClavesNominaInicial(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de las claves de las nóminas que existen en el movimiento de pago
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerClavesNominasHistorial(idMovimientoPago: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerClavesNominasHistorial(idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el detalle del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {string} [idNomina] ID de nomina
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerDetalleMovimientoAdicional(idMovimientoPago: string, idNomina?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerDetalleMovimientoAdicional(idMovimientoPago, idNomina, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de los detalles de movimiento pago por categoría de movimiento de pago
     * @param {string} idCategoriaMovimientoPago ID de la categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerDetallePorCategoriaMovimientoPago(idCategoriaMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el encabezado del padrón de nómina asociado a la nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerEncabezadoPadronNomina(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerEncabezadoPadronNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de las entidades federativas que existen en los pagos de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerEntidadesNomina(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerEntidadesNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el estado de un job
     * @param {string} jobId ID del job
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerEstadoJob(jobId: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerEstadoJob(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de un movimiento pago programado
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerMovimientoProgramado(idMovimientoPago: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerMovimientoProgramado(idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos adicionales activos por cvu investigador y tipo de categoría o clave de detalle
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {string} [claveDetalleMovimiento] clave del detalle de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerMovimientosActivosPorCategoriaDetalle(cvu?: number, claveCategoriaMovimiento?: string, claveDetalleMovimiento?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerMovimientosActivosPorCategoriaDetalle(cvu, claveCategoriaMovimiento, claveDetalleMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos adicionales filtrados
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [tipoMovimiento] Tipo de movimiento
     * @param {string} [categoriaMovimientoPago] Categoría de movimiento pago
     * @param {string} [detalleMovimientoPago] Detalle de movimiento pago
     * @param {string} [clave] Clave del movimiento
     * @param {FrecuenciaMovimientoPago} [frecuenciaMovimientoPago] Frecuencia del movimiento pago
     * @param {boolean} [documentacionMovimiento] Indica si la documentación del movimiento está completa
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerMovimientosAdicionalesFiltrados(idNomina?: string, cvu?: number, nombre?: string, tipoMovimiento?: string, categoriaMovimientoPago?: string, detalleMovimientoPago?: string, clave?: string, frecuenciaMovimientoPago?: FrecuenciaMovimientoPago, documentacionMovimiento?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerMovimientosAdicionalesFiltrados(idNomina, cvu, nombre, tipoMovimiento, categoriaMovimientoPago, detalleMovimientoPago, clave, frecuenciaMovimientoPago, documentacionMovimiento, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los movimientos programados
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [clave] Clave del movimiento
     * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los movimientos programados vigentes por cvu investigador y tipo de categoría
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerMovimientosVigentesPorCategoria(cvu?: number, claveCategoriaMovimiento?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerMovimientosVigentesPorCategoria(cvu, claveCategoriaMovimiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerNomina(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de las nóminas
     * @param {number} [anio] Año de nómina
     * @param {number} [mes] Mes de nómina
     * @param {string} [clave] Clave de la nómina
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerNominas(anio?: number, mes?: number, clave?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerNominas(anio, mes, clave, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * consulta una página de los documentos del movimiento adicional
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPaginaDeArchivos(idMovimientoPago: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPaginaDeArchivos(idMovimientoPago, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pago movimiento pago de beneficiarios por investigador y por movimiento de pago
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [idMovimientoPago] ID del movimiento de pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosBeneficiariosPorInvestigador(cvu?: number, idMovimientoPago?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosBeneficiariosPorInvestigador(cvu, idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos utilizando un ID de nómina y una lista de CVUs.
     * @summary Obtiene lista de pagos por IdNomina y lista de CVUs.
     * @param {string} idNomina ID de la nomina
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosByIdNominaAndCvus(idNomina: string, requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosByIdNominaAndCvus(idNomina, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
     * @param {string} idNomina ID de la nomina
     * @param {Array<EstatusPago>} estatusPagoArray 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos del movimiento de pago
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {string} [clave] Clave de la nómina
     * @param {string} [fecha] Fecha
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosMovimientoPago(idMovimientoPago: string, clave?: string, fecha?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosMovimientoPago(idMovimientoPago, clave, fecha, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {EstatusNomina} estatusNomina 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos del movimiento de pago por id de pago
     * @param {string} idPago ID pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosMovimientosActivosPorPago(idPago: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosMovimientosActivosPorPago(idPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los pagos correspondientes a una nómina
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {string} [clave] Clave de la nómina inicial del pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de los pagos reportados o pendientes de reportar
     * @param {string} idNomina ID de la nomina
     * @param {boolean} reportado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosPorReportado(idNomina, reportado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los pagos correspondientes a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {boolean} conMontoCero 
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de los tipos de receptores y nivel sni que existen en los pagos de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerReceptoresNomina(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerReceptoresNomina(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerReporteRegional(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerReporteRegional(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
     * @param {string} idNomina ID de la nomina
     * @param {boolean} conMontoCero 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerResumenPagos(idNomina, conMontoCero, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerResumenPagosConciliados(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerResumenPagosConciliados(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los datos generales de la solicitud de dispersión al gestor de pagos
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerSolicitudDispersion(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerSolicitudDispersion(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un listado de los tipos de movimiento de pago
     * @param {Array<string>} [clave] Array de clave del movimiento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerTiposMovimientoPago(clave?: Array<string>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerTiposMovimientoPago(clave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerTotalMovimientosDocumentados(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerTotalMovimientosDocumentados(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerTotalPagosPorReportado(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerTotalPagosPorReportado(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Consulta una validación booleana para saber si se permite realizar actualización de documentos
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public obtenerValidacionPuedeActualizarArchivos(idMovimientoPago: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).obtenerValidacionPuedeActualizarArchivos(idMovimientoPago, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valida el archivo de conciliación para subirlo al repositorio DMS
     * @param {string} idNomina ID de la nomina
     * @param {InformacionArchivo} informacionArchivo Información del archivo de conciliación
     * @param {string} [clave] Clave de la nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public subirArchivoConciliacion(idNomina: string, informacionArchivo: InformacionArchivo, clave?: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).subirArchivoConciliacion(idNomina, informacionArchivo, clave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sube un documentos de sustento de movimiento al DMS y guarda la referencia en archivo
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {InformacionArchivo} informacionArchivo Información del documento a cargar
     * @param {TipoArchivo} [tipoArchivo] Tipo de archivo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public subirArchivoMovimientoProgramado(idMovimientoPago: string, informacionArchivo: InformacionArchivo, tipoArchivo?: TipoArchivo, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).subirArchivoMovimientoProgramado(idMovimientoPago, informacionArchivo, tipoArchivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sube documentos al DMS y guarda la referencia en archivo
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {Array<InformacionArchivo>} informacionArchivo Colección de archivos para ser cargados en el DMS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public subirArchivos(idMovimientoPago: string, informacionArchivo: Array<InformacionArchivo>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).subirArchivos(idMovimientoPago, informacionArchivo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los valores de las Umas
     * @param {number} [anio] Año de nómina
     * @param {number} [mes] Mes de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public validarAnioUma(anio?: number, mes?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).validarAnioUma(anio, mes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valida si se puede editar el movimiento adicional
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public validarEdicionMovimientoAdicional(idNomina: string, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).validarEdicionMovimientoAdicional(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Valida si existen pagos en demasía para un investigador fallecido
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public validarExistePagosDemasiaInvestigador(cvu?: number, fechas?: Array<string>, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).validarExistePagosDemasiaInvestigador(cvu, fechas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica si existen movimientos programados vigentes pendientes de aplicar
     * @param {string} idNomina ID de la nomina
     * @param {number} [mes] Mes de nómina
     * @param {number} [anio] Año de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NominaApi
     */
    public verificarMovimientosPendientesPorAplicar(idNomina: string, mes?: number, anio?: number, options?: AxiosRequestConfig) {
        return NominaApiFp(this.configuration).verificarMovimientosPendientesPorAplicar(idNomina, mes, anio, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PagoApi - axios parameter creator
 * @export
 */
export const PagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPago: async (idPago: string, observacion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('eliminarPago', 'idPago', idPago)
            // verify required parameter 'observacion' is not null or undefined
            assertParamExists('eliminarPago', 'observacion', observacion)
            const localVarPath = `/pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idPago !== undefined) {
                localVarQueryParameter['idPago'] = idPago;
            }

            if (observacion !== undefined) {
                localVarQueryParameter['observacion'] = observacion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPrenomina: async (encabezadoPadronNomina: EncabezadoPadronNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'encabezadoPadronNomina' is not null or undefined
            assertParamExists('eliminarPrenomina', 'encabezadoPadronNomina', encabezadoPadronNomina)
            const localVarPath = `/pago/prenomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(encabezadoPadronNomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarPrenomina: async (nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nuevoEncabezadoPadronNomina' is not null or undefined
            assertParamExists('generarPrenomina', 'nuevoEncabezadoPadronNomina', nuevoEncabezadoPadronNomina)
            const localVarPath = `/pago/prenomina`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nuevoEncabezadoPadronNomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosProgramados: async (cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/movimiento-programado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (claveTipoMovimiento !== undefined) {
                localVarQueryParameter['claveTipoMovimiento'] = claveTipoMovimiento;
            }

            if (claveCategoriaMovimiento !== undefined) {
                localVarQueryParameter['claveCategoriaMovimiento'] = claveCategoriaMovimiento;
            }

            if (estatusMovimiento !== undefined) {
                localVarQueryParameter['estatusMovimiento'] = estatusMovimiento;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosConciliados: async (idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosConciliados', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/pagos-conciliados`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosNomina: async (idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idNomina !== undefined) {
                localVarQueryParameter['idNomina'] = idNomina;
            }

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (nombre !== undefined) {
                localVarQueryParameter['nombre'] = nombre;
            }

            if (tipoReceptorNivel !== undefined) {
                localVarQueryParameter['tipoReceptorNivel'] = tipoReceptorNivel;
            }

            if (localizacion !== undefined) {
                localVarQueryParameter['localizacion'] = localizacion;
            }

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }

            if (fechas) {
                localVarQueryParameter['fechas'] = fechas;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReporteRegional: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerReporteRegional', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/reporte-regional`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagos: async (idNomina: string, conMontoCero: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerResumenPagos', 'idNomina', idNomina)
            // verify required parameter 'conMontoCero' is not null or undefined
            assertParamExists('obtenerResumenPagos', 'conMontoCero', conMontoCero)
            const localVarPath = `/pago/{idNomina}/resumen-pagos/{conMontoCero}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"conMontoCero"}}`, encodeURIComponent(String(conMontoCero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagosConciliados: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerResumenPagosConciliados', 'idNomina', idNomina)
            const localVarPath = `/pago/{idNomina}/resumen-pagos-conciliados`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagoApi - functional programming interface
 * @export
 */
export const PagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarPago(idPago: string, observacion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarPago(idPago, observacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarPrenomina(encabezadoPadronNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generarPrenomina(nuevoEncabezadoPadronNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagosConciliados>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerReporteRegional(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReporteRegional>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerReporteRegional(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResumenPagos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerResumenPagos(idNomina, conMontoCero, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerResumenPagosConciliados(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResumenPagosConciliados>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerResumenPagosConciliados(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PagoApi - factory interface
 * @export
 */
export const PagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PagoApiFp(configuration)
    return {
        /**
         * Elimina lógica el pago
         * @param {string} idPago ID del pago
         * @param {string} observacion Observación de un evento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPago(idPago: string, observacion: string, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarPago(idPago, observacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una prenómina
         * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarPrenomina(encabezadoPadronNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
         * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: any): AxiosPromise<void> {
            return localVarFp.generarPrenomina(nuevoEncabezadoPadronNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los movimientos programados
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [clave] Clave del movimiento
         * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
         * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
         * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<Array<MovimientoPago>> {
            return localVarFp.obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: any): AxiosPromise<Array<PagosConciliados>> {
            return localVarFp.obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} [idNomina] ID de nomina
         * @param {number} [cvu] Cvu de receptor de pago
         * @param {string} [nombre] Nombre del receptor de pago
         * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
         * @param {string} [localizacion] Localizacion de receptor de pago
         * @param {string} [clave] Clave de la nómina inicial del pago
         * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerReporteRegional(idNomina: string, options?: any): AxiosPromise<Array<ReporteRegional>> {
            return localVarFp.obtenerReporteRegional(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: any): AxiosPromise<Array<ResumenPagos>> {
            return localVarFp.obtenerResumenPagos(idNomina, conMontoCero, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerResumenPagosConciliados(idNomina: string, options?: any): AxiosPromise<Array<ResumenPagosConciliados>> {
            return localVarFp.obtenerResumenPagosConciliados(idNomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PagoApi - object-oriented interface
 * @export
 * @class PagoApi
 * @extends {BaseAPI}
 */
export class PagoApi extends BaseAPI {
    /**
     * Elimina lógica el pago
     * @param {string} idPago ID del pago
     * @param {string} observacion Observación de un evento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public eliminarPago(idPago: string, observacion: string, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).eliminarPago(idPago, observacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una prenómina
     * @param {EncabezadoPadronNomina} encabezadoPadronNomina Encabezado del padrón relacionado a la prenómina a eliminar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public eliminarPrenomina(encabezadoPadronNomina: EncabezadoPadronNomina, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).eliminarPrenomina(encabezadoPadronNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cálcula y guarda los pagos de la prénomina consultando el padrón de expediente
     * @param {NuevoEncabezadoPadronNomina} nuevoEncabezadoPadronNomina Encabezado del padrón
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public generarPrenomina(nuevoEncabezadoPadronNomina: NuevoEncabezadoPadronNomina, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).generarPrenomina(nuevoEncabezadoPadronNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los movimientos programados
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [clave] Clave del movimiento
     * @param {string} [claveTipoMovimiento] Clave de tipo de movimiento
     * @param {string} [claveCategoriaMovimiento] clave de la categoría de movimiento pago
     * @param {EstatusMovimientoPago} [estatusMovimiento] Estatus del movimiento de pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerMovimientosProgramados(cvu?: number, nombre?: string, clave?: string, claveTipoMovimiento?: string, claveCategoriaMovimiento?: string, estatusMovimiento?: EstatusMovimientoPago, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerMovimientosProgramados(cvu, nombre, clave, claveTipoMovimiento, claveCategoriaMovimiento, estatusMovimiento, fechas, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos aceptados y rechazados (conciliados) de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerPagosConciliados(idNomina: string, tipoReceptor?: TipoReceptor, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerPagosConciliados(idNomina, tipoReceptor, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los pagos correspondientes a una nómina
     * @param {string} [idNomina] ID de nomina
     * @param {number} [cvu] Cvu de receptor de pago
     * @param {string} [nombre] Nombre del receptor de pago
     * @param {string} [tipoReceptorNivel] Tipo de receptor o nivel sni del receptor de un pago
     * @param {string} [localizacion] Localizacion de receptor de pago
     * @param {string} [clave] Clave de la nómina inicial del pago
     * @param {Array<string>} [fechas] Lista de fechas, incluye fecha inicio y fecha fin
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerPagosNomina(idNomina?: string, cvu?: number, nombre?: string, tipoReceptorNivel?: string, localizacion?: string, clave?: string, fechas?: Array<string>, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerPagosNomina(idNomina, cvu, nombre, tipoReceptorNivel, localizacion, clave, fechas, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el reporte por región de pagos de una nómina, no requiere paginación debido a que los registros serán usados para exportar un archivo pdf
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerReporteRegional(idNomina: string, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerReporteRegional(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene una agrupación por tipo de receptor del total de los pagos de una nómina, no requiere paginación debido a que el arreglo no regresa más de 10 elementos
     * @param {string} idNomina ID de la nomina
     * @param {boolean} conMontoCero 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerResumenPagos(idNomina: string, conMontoCero: boolean, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerResumenPagos(idNomina, conMontoCero, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene una agrupación del total de los pagos aceptados y rechazados (conciliados)  de una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoApi
     */
    public obtenerResumenPagosConciliados(idNomina: string, options?: AxiosRequestConfig) {
        return PagoApiFp(this.configuration).obtenerResumenPagosConciliados(idNomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PagoMovimientoPagoApi - axios parameter creator
 * @export
 */
export const PagoMovimientoPagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPagoPorEstatusNomina: async (idMovimientoPago: string, estatusNomina: EstatusNomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idMovimientoPago' is not null or undefined
            assertParamExists('obtenerPagosMovimientoPagoPorEstatusNomina', 'idMovimientoPago', idMovimientoPago)
            // verify required parameter 'estatusNomina' is not null or undefined
            assertParamExists('obtenerPagosMovimientoPagoPorEstatusNomina', 'estatusNomina', estatusNomina)
            const localVarPath = `/pago-movimiento-pago/{idMovimientoPago}/{estatusNomina}`
                .replace(`{${"idMovimientoPago"}}`, encodeURIComponent(String(idMovimientoPago)))
                .replace(`{${"estatusNomina"}}`, encodeURIComponent(String(estatusNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientosActivosPorPago: async (idPago: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idPago' is not null or undefined
            assertParamExists('obtenerPagosMovimientosActivosPorPago', 'idPago', idPago)
            const localVarPath = `/pago-movimiento-pago/{idPago}`
                .replace(`{${"idPago"}}`, encodeURIComponent(String(idPago)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagoMovimientoPagoApi - functional programming interface
 * @export
 */
export const PagoMovimientoPagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PagoMovimientoPagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosMovimientosActivosPorPago(idPago: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PagoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosMovimientosActivosPorPago(idPago, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PagoMovimientoPagoApi - factory interface
 * @export
 */
export const PagoMovimientoPagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PagoMovimientoPagoApiFp(configuration)
    return {
        /**
         * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
         * @param {string} idMovimientoPago ID de movimiento pago
         * @param {EstatusNomina} estatusNomina 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los pagos del movimiento de pago por id de pago
         * @param {string} idPago ID pago
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosMovimientosActivosPorPago(idPago: string, options?: any): AxiosPromise<Array<PagoMovimientoPago>> {
            return localVarFp.obtenerPagosMovimientosActivosPorPago(idPago, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PagoMovimientoPagoApi - object-oriented interface
 * @export
 * @class PagoMovimientoPagoApi
 * @extends {BaseAPI}
 */
export class PagoMovimientoPagoApi extends BaseAPI {
    /**
     * Obtiene los pagos del movimiento de pago aplicados a las nóminas con el estatus indicado
     * @param {string} idMovimientoPago ID de movimiento pago
     * @param {EstatusNomina} estatusNomina 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoMovimientoPagoApi
     */
    public obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago: string, estatusNomina: EstatusNomina, options?: AxiosRequestConfig) {
        return PagoMovimientoPagoApiFp(this.configuration).obtenerPagosMovimientoPagoPorEstatusNomina(idMovimientoPago, estatusNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los pagos del movimiento de pago por id de pago
     * @param {string} idPago ID pago
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagoMovimientoPagoApi
     */
    public obtenerPagosMovimientosActivosPorPago(idPago: string, options?: AxiosRequestConfig) {
        return PagoMovimientoPagoApiFp(this.configuration).obtenerPagosMovimientosActivosPorPago(idPago, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PagosApi - axios parameter creator
 * @export
 */
export const PagosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosExcluyendoEstatus: async (idNomina: string, estatusPagoArray: Array<EstatusPago>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosExcluyendoEstatus', 'idNomina', idNomina)
            // verify required parameter 'estatusPagoArray' is not null or undefined
            assertParamExists('obtenerPagosExcluyendoEstatus', 'estatusPagoArray', estatusPagoArray)
            const localVarPath = `/pago/obtener-excluyendo-estatus/{idNomina}/{estatusPagoArray}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (estatusPagoArray) {
                localVarQueryParameter['estatusPagoArray[]'] = estatusPagoArray;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosPorReportado: async (idNomina: string, reportado: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosPorReportado', 'idNomina', idNomina)
            // verify required parameter 'reportado' is not null or undefined
            assertParamExists('obtenerPagosPorReportado', 'reportado', reportado)
            const localVarPath = `/pago/reportado/{idNomina}/{reportado}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"reportado"}}`, encodeURIComponent(String(reportado)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosTipoReceptor: async (idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerPagosTipoReceptor', 'idNomina', idNomina)
            // verify required parameter 'conMontoCero' is not null or undefined
            assertParamExists('obtenerPagosTipoReceptor', 'conMontoCero', conMontoCero)
            const localVarPath = `/pago/tipo-receptor/{idNomina}/{tipoReceptor}/{conMontoCero}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)))
                .replace(`{${"conMontoCero"}}`, encodeURIComponent(String(conMontoCero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoReceptor !== undefined) {
                localVarQueryParameter['tipoReceptor'] = tipoReceptor;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalMovimientosDocumentados: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerTotalMovimientosDocumentados', 'idNomina', idNomina)
            const localVarPath = `/movimiento-adicional/total-documentados/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalPagosPorReportado: async (idNomina: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idNomina' is not null or undefined
            assertParamExists('obtenerTotalPagosPorReportado', 'idNomina', idNomina)
            const localVarPath = `/pago/reportados-total/{idNomina}`
                .replace(`{${"idNomina"}}`, encodeURIComponent(String(idNomina)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PagosApi - functional programming interface
 * @export
 */
export const PagosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PagosApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosPorReportado(idNomina, reportado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTotalMovimientosDocumentados(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalMovimientosDocumentados>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTotalMovimientosDocumentados(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTotalPagosPorReportado(idNomina: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagosReportadosTotal>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTotalPagosPorReportado(idNomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PagosApi - factory interface
 * @export
 */
export const PagosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PagosApiFp(configuration)
    return {
        /**
         * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
         * @param {string} idNomina ID de la nomina
         * @param {Array<EstatusPago>} estatusPagoArray 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: any): AxiosPromise<number> {
            return localVarFp.obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de los pagos reportados o pendientes de reportar
         * @param {string} idNomina ID de la nomina
         * @param {boolean} reportado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosPorReportado(idNomina, reportado, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la informacion de todos los pagos correspondientes a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {boolean} conMontoCero 
         * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: any): AxiosPromise<Array<Pago>> {
            return localVarFp.obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalMovimientosDocumentados(idNomina: string, options?: any): AxiosPromise<TotalMovimientosDocumentados> {
            return localVarFp.obtenerTotalMovimientosDocumentados(idNomina, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
         * @param {string} idNomina ID de la nomina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTotalPagosPorReportado(idNomina: string, options?: any): AxiosPromise<PagosReportadosTotal> {
            return localVarFp.obtenerTotalPagosPorReportado(idNomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PagosApi - object-oriented interface
 * @export
 * @class PagosApi
 * @extends {BaseAPI}
 */
export class PagosApi extends BaseAPI {
    /**
     * Obtiene los pagos que no tienen los estatus que se pasan por parámetro
     * @param {string} idNomina ID de la nomina
     * @param {Array<EstatusPago>} estatusPagoArray 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagosApi
     */
    public obtenerPagosExcluyendoEstatus(idNomina: string, estatusPagoArray: Array<EstatusPago>, options?: AxiosRequestConfig) {
        return PagosApiFp(this.configuration).obtenerPagosExcluyendoEstatus(idNomina, estatusPagoArray, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de los pagos reportados o pendientes de reportar
     * @param {string} idNomina ID de la nomina
     * @param {boolean} reportado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagosApi
     */
    public obtenerPagosPorReportado(idNomina: string, reportado: boolean, options?: AxiosRequestConfig) {
        return PagosApiFp(this.configuration).obtenerPagosPorReportado(idNomina, reportado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la informacion de todos los pagos correspondientes a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {boolean} conMontoCero 
     * @param {TipoReceptor} [tipoReceptor] Tipo de receptor
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagosApi
     */
    public obtenerPagosTipoReceptor(idNomina: string, conMontoCero: boolean, tipoReceptor?: TipoReceptor, options?: AxiosRequestConfig) {
        return PagosApiFp(this.configuration).obtenerPagosTipoReceptor(idNomina, conMontoCero, tipoReceptor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de los movimientos adicionales documentados asociados a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagosApi
     */
    public obtenerTotalMovimientosDocumentados(idNomina: string, options?: AxiosRequestConfig) {
        return PagosApiFp(this.configuration).obtenerTotalMovimientosDocumentados(idNomina, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de los pagos reportados y pendientes de reportar asociados a una nómina
     * @param {string} idNomina ID de la nomina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PagosApi
     */
    public obtenerTotalPagosPorReportado(idNomina: string, options?: AxiosRequestConfig) {
        return PagosApiFp(this.configuration).obtenerTotalPagosPorReportado(idNomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrenominaApi - axios parameter creator
 * @export
 */
export const PrenominaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados: async (nomina: Nomina, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nomina' is not null or undefined
            assertParamExists('aplicarMovimientosProgramados', 'nomina', nomina)
            const localVarPath = `/movimiento-programado/aplicar-movimientos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nomina, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrenominaApi - functional programming interface
 * @export
 */
export const PrenominaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrenominaApiAxiosParamCreator(configuration)
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aplicarMovimientosProgramados(nomina, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrenominaApi - factory interface
 * @export
 */
export const PrenominaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrenominaApiFp(configuration)
    return {
        /**
         * Aplica los movimientos programados a una prénomina
         * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aplicarMovimientosProgramados(nomina: Nomina, options?: any): AxiosPromise<string> {
            return localVarFp.aplicarMovimientosProgramados(nomina, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PrenominaApi - object-oriented interface
 * @export
 * @class PrenominaApi
 * @extends {BaseAPI}
 */
export class PrenominaApi extends BaseAPI {
    /**
     * Aplica los movimientos programados a una prénomina
     * @param {Nomina} nomina Información de la nómina donde se aplicarán los movimientos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrenominaApi
     */
    public aplicarMovimientosProgramados(nomina: Nomina, options?: AxiosRequestConfig) {
        return PrenominaApiFp(this.configuration).aplicarMovimientosProgramados(nomina, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TipoMovimientoPagoApi - axios parameter creator
 * @export
 */
export const TipoMovimientoPagoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTiposMovimientoPago: async (clave?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tipo-movimiento-pago`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (clave) {
                localVarQueryParameter['clave'] = clave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TipoMovimientoPagoApi - functional programming interface
 * @export
 */
export const TipoMovimientoPagoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TipoMovimientoPagoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerTiposMovimientoPago(clave?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TipoMovimientoPago>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerTiposMovimientoPago(clave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TipoMovimientoPagoApi - factory interface
 * @export
 */
export const TipoMovimientoPagoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TipoMovimientoPagoApiFp(configuration)
    return {
        /**
         * Obtiene un listado de los tipos de movimiento de pago
         * @param {Array<string>} [clave] Array de clave del movimiento
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerTiposMovimientoPago(clave?: Array<string>, options?: any): AxiosPromise<Array<TipoMovimientoPago>> {
            return localVarFp.obtenerTiposMovimientoPago(clave, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TipoMovimientoPagoApi - object-oriented interface
 * @export
 * @class TipoMovimientoPagoApi
 * @extends {BaseAPI}
 */
export class TipoMovimientoPagoApi extends BaseAPI {
    /**
     * Obtiene un listado de los tipos de movimiento de pago
     * @param {Array<string>} [clave] Array de clave del movimiento
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TipoMovimientoPagoApi
     */
    public obtenerTiposMovimientoPago(clave?: Array<string>, options?: AxiosRequestConfig) {
        return TipoMovimientoPagoApiFp(this.configuration).obtenerTiposMovimientoPago(clave, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValorUmaApi - axios parameter creator
 * @export
 */
export const ValorUmaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarAnioUma: async (anio?: number, mes?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valorUma`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (anio !== undefined) {
                localVarQueryParameter['anio'] = anio;
            }

            if (mes !== undefined) {
                localVarQueryParameter['mes'] = mes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValorUmaApi - functional programming interface
 * @export
 */
export const ValorUmaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValorUmaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarAnioUma(anio?: number, mes?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarAnioUma(anio, mes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValorUmaApi - factory interface
 * @export
 */
export const ValorUmaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValorUmaApiFp(configuration)
    return {
        /**
         * Obtiene los valores de las Umas
         * @param {number} [anio] Año de nómina
         * @param {number} [mes] Mes de nómina
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarAnioUma(anio?: number, mes?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.validarAnioUma(anio, mes, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValorUmaApi - object-oriented interface
 * @export
 * @class ValorUmaApi
 * @extends {BaseAPI}
 */
export class ValorUmaApi extends BaseAPI {
    /**
     * Obtiene los valores de las Umas
     * @param {number} [anio] Año de nómina
     * @param {number} [mes] Mes de nómina
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValorUmaApi
     */
    public validarAnioUma(anio?: number, mes?: number, options?: AxiosRequestConfig) {
        return ValorUmaApiFp(this.configuration).validarAnioUma(anio, mes, options).then((request) => request(this.axios, this.basePath));
    }
}


